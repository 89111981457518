import React from "react";
// import { FaInstagram, FaFacebook } from 'react-icons/fa';
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import FlickrIcon from "@mui/icons-material/Camera";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { Box, Button, Stack, Typography } from "@mui/material";
import UWIcon from "../../util/UwIcon";

const Footer = () => {
  return (
    <Box
      sx={{
        color: "engSecondary.main",
        backgroundColor: "#000000",
        pt: "2rem",
        pb: "2rem",
      }}
    >
      <Stack container spacing={1} justifyContent="space-evenly">
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              "button:hover": { filter: "drop-shadow(0 0 1rem #ffffff)" },
            }}
          >
            <Button
              color="engSecondary"
              variant="text"
              onClick={() =>
                (window.location.href =
                  "https://www.facebook.com/ENGOrientation/?ref=aymt_homepage_panel")
              }
            >
              <Stack justifyContent="center" alignItems="center">
                <FacebookIcon />
                <p>Facebook</p>
              </Stack>
            </Button>
            <Button
              color="engSecondary"
              variant="text"
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/engorientation/")
              }
            >
              <Stack justifyContent="center" alignItems="center">
                <InstagramIcon />
                <p>Instagram</p>
              </Stack>
            </Button>
            <Button
              color="engSecondary"
              variant="text"
              onClick={() =>
                (window.location.href =
                  "https://www.flickr.com/photos/froshmedia/")
              }
            >
              <Stack justifyContent="center" alignItems="center">
                <FlickrIcon />
                <p>Flickr</p>
              </Stack>
            </Button>
          </Box>
        </div>
        <Typography variant="body1" align="center">
          <UWIcon icon_name="GEARS_61" font_size="2.25rem" />
          Engineered by UW Plummers
          <UWIcon icon_name="GEARS_61" font_size="2.25rem" className="mirror" />
        </Typography>
        <Typography variant="body1" align="center">
          <CopyrightIcon /> University of Waterloo Engineering Orientation 2024
        </Typography>
      </Stack>
    </Box>
  );
};

export default Footer;
