import React from "react";
import { Box, Card, CardMedia } from "@mui/material";

import "./video.css";

export const YtVideo = ({ url }) => {
  return (
    <Box
      display="block"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", maxWidth: "1200px" }}
    >
      <Card className="iframe-container">
        <CardMedia
          component="iframe"
          className="responsive-iframe"
          src={url}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Card>
    </Box>
  );
};
