import axios from "axios";
import toast from "react-simple-toasts";

// Update user data from the database
const getUserData = (setUserData, forceLogin = true) => {
  // Try to collect user data from the database
  axios
    .get("/api/user-info", {
      withCredentials: true,
    })
    .then((res) => {
      setUserData(res.data);
    })
    .catch((err) => {
      if (err.response && err.response.status === 401) {
        if (forceLogin) window.location.replace("/login");
      } else {
        if (forceLogin) {
          console.log(err);
          toast(
            "Sorry, seems there was an internal website issue. Could not fetch user data!"
          );
        }
      }
    });
};

export default getUserData;
