import { Typography } from "@mui/material";
import React from "react";
import { Box, Stack, Button } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import WaterlooLogo from "../../util/WaterlooLogo";

const MoveIn = () => {
  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack
          justifyContent="center"
          alignItems="stretch"
          sx={{ width: "100%", paddingBottom: "2em" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ padding: "2em", backgroundColor: "engSecondary.light" }}
          >
            <Stack justifyContent="center" aligntItems="center">
              <Typography variant="h2" align="center">
                Move-In Information
              </Typography>
              <Typography
                variant="h5"
                align="center"
                style={{ maxWidth: "800px" }}
              >
                Welcome to the University of Waterloo! If you are planning to
                stay in the Campus Residences in your incoming term, see below
                for the best places to look for Move In information!
              </Typography>
            </Stack>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ padding: "2em", backgroundColor: "#FFFFFF" }}
          >
            <Stack justifyContent="center" aligntItems="center">
              <Typography variant="h3" align="center">
                Dates <CalendarMonthIcon sx={{ fontSize: "1.25em" }} />
              </Typography>
              <Typography
                variant="h5"
                align="center"
                style={{ maxWidth: "800px" }}
              >
                Thursday, August 29, 2024
                <br />─ to ─<br />
                Sunday, September 1, 2024
              </Typography>
            </Stack>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ padding: "2em", backgroundColor: "engSecondary.light" }}
          >
            <Stack justifyContent="center" aligntItems="center">
              <Typography variant="h3" align="center">
                Residence Move-Ins
              </Typography>
              <Typography
                variant="h5"
                align="center"
                style={{ maxWidth: "800px" }}
              >
                Looking for Residence Maps, Move-In times, FAQ on what to bring
                to Residence, etc? Head to the Campus Housing Move Ins page for
                all the information you will need to get settled into residence.
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="engPrimary"
                  onClick={() =>
                    window.open(
                      "https://uwaterloo.ca/campus-housing/moving-fy",
                      "_blank"
                    )
                  }
                  sx={{ fontSize: "1.25em", padding: "0.5em" }}
                >
                  <Stack>
                    <p>Move In Information</p>
                    <WaterlooLogo style={{ maxHeight: "40px" }} />
                  </Stack>
                </Button>
              </Box>
            </Stack>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ padding: "2em", backgroundColor: "#ffffff" }}
          >
            <Stack justifyContent="center" aligntItems="center">
              <Typography variant="h3" align="center">
                Orientation Calendar
              </Typography>
              <Typography
                variant="h5"
                align="center"
                style={{ maxWidth: "800px" }}
              >
                Check your Portal calendar for the move-in timelines,
                orientation check-in dates/times, and even more info on
                Orientation Week events.
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="engPrimary"
                  onClick={() =>
                    window.open(
                      "https://portal.uwaterloo.ca/#/calendar",
                      "_blank"
                    )
                  }
                  sx={{ fontSize: "1.25em", padding: "0.5em" }}
                >
                  <Stack>
                    <p>Orientation Calendar</p>
                    <img
                      src="/images/resource-logos/portal_inv.png"
                      alt="Portal Logo"
                      style={{
                        maxHeight: "40px",
                        objectFit: "contain",
                      }}
                    />
                  </Stack>
                </Button>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default MoveIn;
