import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import { toastConfig } from "react-simple-toasts";

//  Fonts
import "@fontsource/barlow-condensed"; // Defaults to weight 400
import "@fontsource/noto-serif-georgian";

// import "bootstrap/dist/css/bootstrap.min.css";
import "react-simple-toasts/dist/theme/frosted-glass.css";

import App from "./App";
import { EngOrientationTheme } from "./theme";

// Set global default toast theme
toastConfig({
  theme: "frosted-glass",
});

// Render the application
var root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={EngOrientationTheme}>
      <div style={{ backgroundColor: "white" }}>
        <App />
      </div>
    </ThemeProvider>
  </React.StrictMode>
);
