import React, { useEffect } from "react";
import { Grid, Box, Stack, Typography } from "@mui/material";

const desktopImage = "/images/countdown-bg-sm.jpg";
const mobileImage = "/images/countdown-bg.jpg";

const Countdown = ({ deadline }) => {
  const imageUrl = window.innerWidth >= 650 ? desktopImage : mobileImage;

  useEffect(() => {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    const countDown = new Date(deadline).getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDown - now;

      const days = Math.floor(distance / day);
      const hours = Math.floor((distance % day) / hour);
      const minutes = Math.floor((distance % hour) / minute);
      const seconds = Math.floor((distance % minute) / second);

      // Set the values in the rendered component
      if (distance > 0) {
        document.getElementById("days").innerText = days;
        document.getElementById("hours").innerText = hours;
        document.getElementById("minutes").innerText = minutes;
        document.getElementById("seconds").innerText = seconds;
      } else {
        clearInterval(interval);
        document.getElementById("days").innerText = 0;
        document.getElementById("hours").innerText = 0;
        document.getElementById("minutes").innerText = 0;
        document.getElementById("seconds").innerText = 0;
      }
    }, 0);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        "-webkit-text-stroke": "1px rgb(36, 36, 36)",
        textShadow:
          "3px 3px 0px rgb(36, 36, 36), \
             3px 3px 10px rgb(36, 36, 36)",
        pt: "2rem",
        pb: "2rem",
        width: "100%",
      }}
    >
      <Grid container spacing={3} justifyContent="space-evenly">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              color="engSecondary.light"
              variant="h1"
              align="center"
              sx={{ fontSize: "4rem", padding: "0px !important" }}
            >
              Countdown to Orientation Week
            </Typography>
          </Box>
        </Grid>
        {["days", "hours", "minutes", "seconds"].map((item) => (
          <Grid item xs={5} md={3} className="time-item" key={item}>
            <Stack justifyContent="center" alignItems="center">
              <Typography
                id={item}
                variant="h1"
                color="engSecondary.light"
                align="center"
                sx={{ padding: "0px !important" }}
              />
              <Typography
                variant="h1"
                color="engSecondary.light"
                align="center"
                sx={{
                  padding: "0px !important",
                  textTransform: "uppercase",
                  fontSize: "2.5rem",
                }}
              >
                {item}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Countdown;
