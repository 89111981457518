import React, { useState, useEffect, useLayoutEffect } from "react";
import toast from "react-simple-toasts";
import axios from "axios";
import { Card, Stack, Box, Typography } from "@mui/material";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

import "./colors.css";
import LoadingContent from "../components/loading";
import getUserData from "../util/getUserData";
import useFitText from "../util/FitText";

const TeamsPage = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [teamsData, setTeamsData] = useState([]);

  const {
    fontSize: fontSize,
    itemRef: itemRef,
    containerRef: containerRef,
    forceRecalc: forceRecalcTextSize,
    isComputed: textSizeIsComputed,
  } = useFitText({
    maxSize: 20,
    minSize: 8,
  });

  const sortByPoints = (data) => {
    data.sort((a, b) => {
      // Sort by Points
      if (b.points - a.points > 0) return 1;
      if (a.points - b.points > 0) return -1;
      // Sort by colour
      const a_colour = a.colour.split(" ")[1];
      const b_colour = b.colour.split(" ")[1];
      if (a_colour > b_colour) return 1;
      if (a_colour < b_colour) return -1;
      // Sort by hue
      const a_hue = a.colour.split(" ")[0];
      const b_hue = b.colour.split(" ")[0];
      if (a_hue > b_hue) return 1;
      if (a_hue < b_hue) return -1;
    });
    return data;
  };

  // Get the rank of the colour team
  // Allow ties for rank, but skip the tied ranks
  // ie: 1, 2, 2, 4, 5, 5, 5, 8, 9, 10
  const getPlace = (colour, badge) => {
    const filteredTeams = teamsData.filter((team) => team.visible);
    // Get raw rank
    const index = filteredTeams.findIndex((team) => team.colour === colour) + 1;
    // Bump down rank if tied with those "lower"
    var rank = index;
    for (var i = 0; i < index - 1; i++) {
      if (filteredTeams[i].points === filteredTeams[index - 1].points) {
        rank--;
      }
    }
    // Add text suffix
    if (rank > 3) return <span>{rank}th</span>;
    switch (rank % 10) {
      case 1:
        if (badge)
          return (
            <span>
              {rank}st <br />
              <MilitaryTechIcon fontSize="large" />
            </span>
          );
        else return <span>{rank}st</span>;
      case 2:
        if (badge)
          return (
            <span>
              {rank}nd <br />
              <MilitaryTechIcon fontSize="medium" />
            </span>
          );
        else return <span>{rank}nd</span>;
      case 3:
        if (badge)
          return (
            <span>
              {rank}rd <br />
              <MilitaryTechIcon fontSize="small" />
            </span>
          );
        else return <span>{rank}rd</span>;
    }
  };

  const getTeamsData = () => {
    axios
      .get("/api/team-points")
      .then((res) => {
        setTeamsData(sortByPoints(res.data));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast(
          "Sorry, seems there was an internal website issue. Could not fetch teams data!"
        );
      });
  };

  // Force recalculate text size when teams data changes
  useLayoutEffect(() => {
    forceRecalcTextSize();
  }, [teamsData]);

  useEffect(() => {
    // Get user data if logged in, dont force login
    getUserData(setUserData, false);
    // Always get teams data
    getTeamsData();
  }, []);

  return (
    <>
      <Box display={loading ? "block" : "none"}>
        <LoadingContent />
      </Box>
      <Box display={loading ? "none" : "block"}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            display="block"
            sx={{ maxWidth: "100%", padding: "0px" }}
            justifyContent="center"
          >
            <Box
              display="block"
              sx={{
                padding: { sx: "0em", sm: "1em" },
                maxWidth: "750px",
              }}
            >
              <Stack
                ref={containerRef}
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {/* Show the "Your Place" card if the user of a visible colour team */}
                <Card
                  sx={{
                    color: "info.contrastText",
                    backgroundColor: "info.main",
                    padding: "1em",
                    textAlign: "center",
                    width: "90%",
                    maxWidth: "600px",
                    display: !!userData && userData.visible ? "block" : "none",
                  }}
                >
                  <Typography variant="h5" align="center">
                    You are in {getPlace(userData.colour, false)} place with{" "}
                    {teamsData.filter(
                      (team) => team.colour == userData.colour_team
                    )?.[0]?.points || 0}{" "}
                    point
                    {teamsData.filter(
                      (team) => team.colour == userData.colour_team
                    )?.[0]?.points == 1
                      ? ""
                      : "s"}
                    !
                  </Typography>
                </Card>

                <Table
                  ref={itemRef}
                  sx={{
                    opacity: textSizeIsComputed ? "100%" : "0%",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "engSecondary.light",
                        color: "engSecondary.contrastText",
                        display: "inline-table",
                        borderRadius: "10px",
                        width: "100%",
                      }}
                    >
                      <TableCell
                        sx={{ width: "15%", fontSize: fontSize }}
                        align="center"
                      >
                        <Typography variant="h6">Rank</Typography>
                      </TableCell>
                      <TableCell
                        sx={{ width: "55%", fontSize: fontSize }}
                        align="center"
                      >
                        <Typography variant="h6">Team</Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "30%",
                          paddingRight: "2em",
                          fontSize: fontSize,
                        }}
                        align="right"
                      >
                        <Typography variant="h6">Points</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teamsData
                      .filter((team) => team.visible)
                      .map((team, index) => (
                        <TableRow
                          key={team.colour}
                          classname={team.colour_code}
                          sx={{
                            display: "inline-table",
                            width: "100%",
                            borderRadius: "10px",
                            overflow: "hidden",
                            ".MuiTypography-root": {
                              fontSize: fontSize,
                            },
                          }}
                        >
                          <TableCell
                            sx={{ width: "15%" }}
                            align="center"
                            className={team.colour_code}
                          >
                            <Typography variant="h3">
                              <Box
                                justifyContent="center"
                                display="block"
                                sx={{ width: "35px" }}
                              >
                                {getPlace(team.colour, true)}
                              </Box>
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ width: "55%" }}
                            align="center"
                            className={team.colour_code}
                          >
                            <Typography noWrap variant="h3">
                              {team.colour + " " + team.team_name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ width: "30%", paddingRight: "2em" }}
                            align="right"
                            className={team.colour_code}
                          >
                            <Typography variant="h3">
                              {team.points || 0}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
// };

export default TeamsPage;
