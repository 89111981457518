import "./profile.css";
import React, {
  useState,
  useEffect,
  componentDidMount,
  componentWillUnmount,
} from "react";
import { useCookies } from "react-cookie";
import toast from "react-simple-toasts";
import axios from "axios";
import LoadingContent from "../components/loading";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import LogoutIcon from "@mui/icons-material/Logout";
import MapIcon from "@mui/icons-material/Map";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Collapse,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
  Stack,
  Typography,
} from "@mui/material";
import useFitText from "../util/FitText";
import SocialLink from "../util/SocialLink";

import getUserData from "../util/getUserData";

const positionNiceName = (position) => {
  //'fy','big','huge','edcom','media','nld','oteam'
  switch (position) {
    case "fy":
      return "First Year";
    case "big":
      return "Big";
    case "huge":
      return "Huge";
    case "comhuge":
      return "ComHuge";
    case "edcom":
      return "EdCom";
    case "comedcom":
      return "ComEdCom";
    case "media":
      return "Media";
    case "media-lead":
      return "Media Lead";
    case "nld":
      return "Non-Leader Director";
    case "prez":
      return "Eng Soc President";
    case "foc":
      return "FOC";
    default:
      return "Error: Position not found";
  }
};

const departmentNiceName = (department) => {
  //'arch', 'ae', 'bme', 'chem', 'civ', 'ece', 'env', 'geo', 'mgmt', 'mech', 'tron', 'nano', 'se', 'syde'
  switch (department) {
    case "arch":
      return "Architecture";
    case "AE":
      return "Architectural Engineering";
    case "BIOMEDE":
      return "Biomedical Engineering";
    case "CHE":
      return "Chemical Engineering";
    case "CIVE":
      return "Civil Engineering";
    case "COMPE":
      return "Computer Engineering";
    case "ELE":
      return "Electrical Engineering";
    case "ENVE":
      return "Environmental Engineering";
    case "GEOE":
      return "Geological Engineering";
    case "MGTE":
      return "Management Engineering";
    case "ME":
      return "Mechanical Engineering";
    case "MECTR":
      return "Mechatronics Engineering";
    case "NE":
      return "Nanotechnology Engineering";
    case "SE":
      return "Software Engineering";
    case "SYDE":
      return "Systems Design Engineering";
    default:
      return "Error: Department not found";
  }
};

const positionHat = (position) => {
  //'fy','big','huge','edcom','media','nld','oteam'
  switch (position) {
    case "fy":
      return "./images/hats/hat-fy-crunch.png";
    case "big":
      return "./images/hats/hat-big-crunch.png";
    case "huge":
      return "./images/hats/hat-huge-crunch.png";
    case "comhuge":
      return "./images/hats/hat-comhuge-crunch.png";
    case "edcom":
      return "./images/hats/hat-edcom-crunch.png";
    case "comedcom":
      return "./images/hats/hat-comedcom-crunch.png";
    case "media":
      return "./images/hats/hat-media-crunch.png";
    case "media-lead":
      return "./images/hats/hat-media-lead-crunch.png";
    case "nld":
      return "./images/hats/hat-nld-crunch.png";
    case "prez":
      return "./images/hats/hat-prez-crunch.png";
    case "foc":
      return "./images/hats/hat-foc-crunch.png";
    default:
      return "./images/hats/hat-fy-crunch.png";
  }
};

// Sort students by position and name, in that order
const sortStudents = (students) => {
  const roles = [
    "foc",
    "prez",
    "media-lead",
    "comedcom",
    "comhuge",
    "huge",
    "edcom",
    "media",
    "big",
    "nld",
    "fy",
  ];
  let sortedStudents = [];
  roles.forEach(
    (role) =>
      (sortedStudents = [
        ...sortedStudents,
        ...students
          .filter((leader) => leader.position === role)
          .sort((leader) => leader.fullname),
      ])
  );
  return sortedStudents;
};

function StudentCard({ student }) {
  // Resize the Fullname font to fit the card. Avoids long name overflow.
  const { fontSize: fontSize, itemRef: fontSizeRef } = useFitText({
    maxSize: 20,
    minSize: 10,
  });

  return (
    <Card
      style={{
        minWidth: 100,
        borderRadius: "5px",
        height: "100%",
        padding: "5px",
        overflow: "hidden",
      }}
      class={`gradient-${student.position || "fy"}`}
    >
      <Stack container spacing={0} direction="column" alignItems="center">
        <Stack item>
          <CardMedia
            component="img"
            image={positionHat(student.position || "fy")}
            alt="Hardhat"
            fluid
            style={{ width: "100%", maxWidth: "150px" }}
          />
        </Stack>
        <Stack item width="100%">
          <Typography
            ref={fontSizeRef}
            gutterBottom
            variant="h5"
            component="div"
            align="center"
            style={{ fontSize }}
          >
            {student.fullname}
          </Typography>
        </Stack>
        <Stack item>
          <Stack
            container
            divider={<Divider orientation="horizontal" flexItem />}
            spacing={0}
            direction="column"
            alignItems="center"
          >
            {!!student.position && student.position !== "fy" && (
              <Stack item>
                <Typography
                  variant="body2"
                  align="center"
                  style={{ opacity: 0.6 }}
                >
                  {positionNiceName(student.position)}
                </Typography>
              </Stack>
            )}
            {!!student.department && (
              <Stack item>
                <Typography
                  variant="body2"
                  align="center"
                  style={{ opacity: 0.6 }}
                >
                  {departmentNiceName(student.department)}
                </Typography>
              </Stack>
            )}
            {!!student.directorships && (
              <Stack item>
                <Typography
                  variant="body2"
                  align="center"
                  style={{ opacity: 0.6 }}
                >
                  {student.directorships}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

function StudentGrid({ sizes, students }) {
  return (
    students &&
    students.length > 0 && (
      <Grid
        container
        spacing={1}
        alignItems="stretch"
        alignContent="center"
        justifyContent="center"
        justify="center"
      >
        {sortStudents(students)?.map((student) => (
          <Grid item {...sizes} key={student.fullname}>
            <StudentCard student={student} />
          </Grid>
        ))}
      </Grid>
    )
  );
}

const ProfileCard = ({ userData }) => {
  // Resize the Fullname font to fit the card. Avoids long name overflow.
  const { fontSize: fontSize, itemRef: fontSizeRef } = useFitText({
    maxSize: 20,
    minSize: 10,
  });

  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <Card
            raised
            sx={{
              borderRadius: "5px",
              backgroundColor: "engSecondary.light",
              color: "engSecondary.contrastText",
            }}
          >
            <Grid
              container
              spacing={0}
              justifyContent="center"
              alignItems="stretch"
              sx={{
                ".info-chunk": {
                  pt: "20px",
                  pb: "20px",
                  pl: "10px",
                  pr: "10px",
                },
              }}
            >
              {/* Hardhat, Name, and Titles */}
              <Grid item xs={12} sm={4}>
                <Stack
                  container
                  spacing={0}
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ height: "100%", width: "100%" }}
                  className={`gradient-${userData?.position} info-chunk`}
                >
                  {/* User's Hardhat */}
                  <Stack item xs={6}>
                    <CardMedia
                      component="img"
                      image={positionHat(userData?.position)}
                      alt="Hardhat"
                      fluid
                      style={{ width: "100%", maxWidth: "200px" }}
                    />
                  </Stack>
                  {/* User's Name */}
                  <Stack item xs={1} width="100%">
                    <Typography
                      ref={fontSizeRef}
                      gutterBottom
                      variant="h5"
                      align="center"
                      component="div"
                      style={{ fontSize }}
                    >
                      {userData?.fullname}
                    </Typography>
                  </Stack>
                  {/* User's Position */}
                  <Stack item xs={1}>
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ opacity: 0.8 }}
                    >
                      {positionNiceName(userData?.position)}
                    </Typography>
                  </Stack>
                  {/* User's Directorships */}
                  <Stack
                    item
                    xs={2}
                    display={{
                      xs: userData?.directorships ? "block" : "none",
                    }}
                  >
                    <Typography variant="body2" style={{ opacity: 0.8 }}>
                      {userData?.directorships}
                    </Typography>
                  </Stack>
                  {/* Logout Button */}
                  <Stack item xs={1}>
                    <Button
                      color="error"
                      variant="contained"
                      endIcon={<LogoutIcon size="small" />}
                      onClick={() => window.location.replace("/logout")}
                      style={{ marginTop: "0.5rem" }}
                    >
                      <Typography
                        variant="body2"
                        className="no-shadow"
                        style={{ fontSize: "8pt" }}
                      >
                        Logout
                      </Typography>
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
              {/* User Information section */}
              <Grid item xs={12} sm={8}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  className="info-chunk"
                >
                  {/* Student Information Section */}
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ fontSize: "14pt" }}
                    >
                      Student Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: "0px" }}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">WATiam</Typography>
                    <Typography variant="body2" style={{ opacity: "60%" }}>
                      {userData?.watiam}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Department</Typography>
                    <Typography variant="body2" style={{ opacity: "60%" }}>
                      {departmentNiceName(userData?.department)}
                    </Typography>
                  </Grid>
                  {/* Orientation Information Section */}
                  <Grid item xs={12}>
                    <Typography
                      variant="body2"
                      align="center"
                      color="text.primary"
                      style={{ fontSize: "14pt" }}
                    >
                      Orientation Information
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: "0px" }}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Colour Team</Typography>
                    <Box display="flex">
                      <Typography
                        variant="body2"
                        className={userData?.colour_code}
                        style={{ borderRadius: "3px", padding: "3px" }}
                      >
                        {userData?.colour_team + " " + userData?.team_name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Points Collected</Typography>
                    <Typography variant="body2" style={{ opacity: "60%" }}>
                      {userData?.points || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Colour Team HQ</Typography>
                    <Button
                      variant="contained"
                      color="info"
                      endIcon={<MapIcon size="small" />}
                      onClick={() =>
                        window.open(
                          "https://uwaterloo.ca/plant-operations/floor-plans"
                        )
                      }
                    >
                      {userData?.hq_location}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">Team Social</Typography>
                    <Typography variant="body2">
                      {userData?.social_link !== null &&
                      userData?.social_link !== "" ? (
                        <SocialLink
                          url={userData?.social_link}
                          style={{ width: "20px", height: "20px" }}
                        />
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Box>
    </div>
  );
};

const DirectorSection = ({ userData }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [directorData, setDirectorData] = useState(null);
  const [selectedDirectorship, setSelectedDirectorship] = useState("");

  // Update director data from the database
  const getDirectorData = () => {
    axios
      .get("/api/director-list", {
        withCredentials: true,
      })
      .then((res) => {
        setDirectorData(res.data);
      })
      .catch((err) => {
        if (userData && userData?.position !== "fy")
          toast(
            "Sorry, seems there was an internal website issue. Could not reload director list!",
            8000
          );
      });
  };

  // Update the selected directorship and handle the collapse animation
  const handleDirectorshipChange = (e) => {
    if (
      selectedDirectorship === e.target.value ||
      selectedDirectorship === null
    ) {
      setSelectedDirectorship(e.target.value);
      setCollapseOpen(e.target.value !== null);
    } else {
      setCollapseOpen(false);
      setTimeout(() => {
        setSelectedDirectorship(e.target.value);
        setCollapseOpen(e.target.value !== null);
      }, 220);
    }
  };

  // Update director data on page load
  useEffect(() => {
    if (!!userData) getDirectorData();
  }, [userData]);

  return (
    <div>
      {directorData ? (
        <>
          <Typography variant="h3" align="center" color="text.primary">
            List of Directors
          </Typography>
          <Divider direction="horizontal" />
          <Box
            display="flex"
            justifyContent="center"
            style={{ padding: "10px" }}
          >
            <FormControl fullWidth style={{ maxWidth: "500px" }}>
              <InputLabel id="director-select-label">Directorship</InputLabel>
              <Select
                labelId="director-select-label"
                id="director-select"
                value={selectedDirectorship}
                label="Directorship"
                onChange={handleDirectorshipChange}
              >
                {[
                  <MenuItem value={""} key="blank-option">
                    <ClearAllIcon />
                  </MenuItem>,
                ].concat(
                  Object.keys(directorData)?.map((directorship) => (
                    <MenuItem key={directorship} value={directorship}>
                      {directorship}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Box>
        </>
      ) : (
        <LoadingContent />
      )}
      <Collapse id="director-grid-collapse" in={collapseOpen} timeout={200}>
        <StudentGrid
          sizes={{ xs: 6, sm: 4, md: 3 }}
          students={directorData ? directorData[selectedDirectorship] : []}
        />
      </Collapse>
    </div>
  );
};

const LeaderSection = ({ userData }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [leaderData, setLeaderData] = useState({});
  const [selectColourTeamOptions, setSelectColourTeamOptions] = useState([]);
  const [selectedColourTeam, setSelectedColourTeam] = useState("");

  // Update leader data from the database
  const updateLeaderData = (colour_team) => {
    axios
      .get("/api/leader-list", {
        withCredentials: true,
      })
      .then((res) => {
        setLeaderData(res.data);
      })
      .catch((err) => {
        if (userData && userData?.position !== "fy")
          toast(
            "Sorry, seems there was an internal website issue. Could not reload leader list!",
            8000
          );
      });
  };

  // Update the selected colour team and handle the collapse animation
  const handleColourTeamChange = (e) => {
    if (selectedColourTeam === e.target.value || selectedColourTeam === null) {
      setSelectedColourTeam(e.target.value);
      setCollapseOpen(e.target.value !== null);
    } else {
      setCollapseOpen(false);
      setTimeout(() => {
        setSelectedColourTeam(e.target.value);
        setCollapseOpen(e.target.value !== null);
      }, 220);
    }
  };

  // Update leader data on page load
  useEffect(() => {
    if (!!userData) updateLeaderData();
  }, [userData]);

  // Update the select list when leader data or userData changes
  useEffect(() => {
    setSelectColourTeamOptions(
      Object.keys(leaderData)
        ?.filter((colour) => userData.position !== "fy")
        ?.filter((colour) => colour !== userData?.colour_team)
    );
  }, [leaderData, userData]);

  // Pre-set leader view for FYs
  useEffect(() => {
    if (userData?.position === "fy") {
      handleColourTeamChange({
        target: { value: userData?.colour_team || "" },
      });
    }
  }, [selectColourTeamOptions]);

  return (
    <div>
      {leaderData ? (
        <>
          <Typography variant="h3" align="center" color="text.primary">
            List of Leaders
          </Typography>
          <Divider direction="horizontal" />
          <Box
            display="flex"
            justifyContent="center"
            style={{ padding: "10px" }}
          >
            <FormControl fullWidth style={{ maxWidth: "500px" }}>
              <InputLabel id="leader-team-select-label">
                Leader Colour Team
              </InputLabel>
              <Select
                labelId="leader-team-select-label"
                id="leader-team-select"
                label="Leader Colour Team"
                value={selectedColourTeam}
                onChange={handleColourTeamChange}
              >
                {[
                  <MenuItem value={""} key="blank-option">
                    <ClearAllIcon />
                  </MenuItem>,
                  <MenuItem value={userData?.colour_team} key="user-team">
                    {userData?.colour_team}
                  </MenuItem>,
                  <ListSubheader key="user-team-seperator">
                    <Divider
                      direction="horizontal"
                      display={
                        selectColourTeamOptions?.length > 0 ? "block" : "none"
                      }
                    />
                  </ListSubheader>,
                ].concat(
                  selectColourTeamOptions.map((team) => (
                    <MenuItem key={team} value={team}>
                      {team}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Box>
        </>
      ) : (
        <LoadingContent />
      )}
      <Collapse id="leader-grid-collapse" in={collapseOpen} timeout={200}>
        <StudentGrid
          sizes={{ xs: 6, sm: 4, md: 3 }}
          students={leaderData ? leaderData[selectedColourTeam] : []}
        />
      </Collapse>
    </div>
  );
};

const FirstYearSection = ({ userData }) => {
  const [fyData, setFYData] = useState(null);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [colourTeamList, setColourTeamList] = useState([]);
  const [colourTeamViewOptions, setColourTeamViewOptions] = useState([]);
  const [colourTeamView, setColorTeamView] = useState("");

  // Update first year data from the database
  const updateFYData = (colour_team) => {
    if (!colour_team || colour_team === "") return;
    axios
      .get("/api/fy-list", {
        withCredentials: true,
        params: { colour_team: colour_team },
      })
      .then((res) => {
        setFYData(res.data);
      })
      .catch((err) => {
        if (userData && userData?.position !== "fy")
          toast(
            "Sorry, seems there was an internal website issue. Could not reload first year list!",
            8000
          );
      });
  };

  // Update the colour team list from the database
  const updateTeamList = () => {
    axios
      .get("/api/team-list")
      .then((res) => {
        setColourTeamList(res.data);
      })
      .catch((err) => {
        if (userData && userData?.position !== "fy")
          toast(
            "Sorry, seems there was an internal website issue. Could not get the list of colour teams!",
            8000
          );
      });
  };

  // Handle a the change of colour team and the collapse animation
  const handleColourTeamChange = (e) => {
    if (colourTeamView === e.target.value || !colourTeamView) {
      setColorTeamView(e.target.value);
      setCollapseOpen(!!e.target.value);
    } else {
      setCollapseOpen(false);
      setTimeout(() => {
        setColorTeamView(e.target.value);
        setCollapseOpen(!!e.target.value);
      }, 320);
    }
  };

  // Update colour team list on page load
  useEffect(() => {
    if (!!userData) updateTeamList();
  }, [userData]);

  // Update colour team view options on change of colour team list or userData
  useEffect(() => {
    setColourTeamViewOptions(
      colourTeamList
        .filter((team) =>
          ["foc", "comhuge", "comedcom"].includes(userData?.position)
        )
        .filter((team) => team.colour !== userData?.colour_team)
    );
  }, [colourTeamList, userData]);

  // Update first year data on change of colour team
  useEffect(() => {
    setFYData(null); // Reset first year data
    updateFYData(colourTeamView); // Update first year data
  }, [colourTeamView]);

  return (
    <div>
      <Typography variant="h3" align="center" color="text.primary">
        List of First Years
      </Typography>
      <Divider direction="horizontal" />
      <Box display="flex" justifyContent="center" style={{ padding: "10px" }}>
        <FormControl fullWidth style={{ maxWidth: "500px" }}>
          <InputLabel id="fy-team-select-label">
            First Year Colour Team
          </InputLabel>
          <Select
            labelId="fy-team-select-label"
            id="fy-team-select"
            label="First Year Colour Team"
            value={colourTeamView}
            onChange={handleColourTeamChange}
          >
            {[
              <MenuItem value={""} key="blank-option">
                <ClearAllIcon />
              </MenuItem>,
              <MenuItem value={userData?.colour_team} key="user-team">
                {userData?.colour_team}
              </MenuItem>,
              <ListSubheader key="user-team-seperator">
                <Divider
                  direction="horizontal"
                  display={colourTeamViewOptions?.length > 0 ? "box" : "none"}
                />
              </ListSubheader>,
            ].concat(
              colourTeamViewOptions.map((team) => (
                <MenuItem key={team.colour} value={team.colour}>
                  {team.colour}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      </Box>
      {/* Show loading content when colour team selected and data not present. */}
      <Collapse in={collapseOpen && !fyData} timeout={100}>
        <LoadingContent />
      </Collapse>
      {/* Show the FY list when fyData and colourTeamView are valid values */}
      <Collapse in={collapseOpen && fyData} timeout={300}>
        <StudentGrid sizes={{ xs: 6, sm: 4, md: 2 }} students={fyData} />
      </Collapse>
    </div>
  );
};

const ProfilePage = () => {
  const authCookieName = process.env.REACT_APP_AUTH_COOKIE_NAME;
  const [cookie, setCookie, removeCookie] = useCookies([authCookieName]);

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  // Update user data on page load
  useEffect(() => {
    getUserData(setUserData);
  }, []);

  return (
    <div>
      <Collapse in={!userData}>
        <LoadingContent />
      </Collapse>
      <Collapse in={!!userData}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={{
              width: "100%",
              maxWidth: "1000px",
              paddingRight: "1rem",
              paddingLeft: "1rem",
              paddingTop: "1rem",
              paddingBottom: "2rem",
            }}
          >
            <Stack
              spacing={4}
              display="block"
              justifyContent="center"
              alignItems="center"
            >
              <ProfileCard userData={userData} />
              <Box
                display={
                  userData && userData?.position !== "fy" ? "block" : "none"
                }
              >
                <DirectorSection userData={userData} />
              </Box>
              <LeaderSection userData={userData} />
              <Box
                display={
                  userData && userData?.position !== "fy" ? "block" : "none"
                }
              >
                <FirstYearSection userData={userData} />
              </Box>
            </Stack>
          </Box>
        </Box>
      </Collapse>
    </div>
  );
};

export default ProfilePage;
