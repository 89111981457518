import React from "react";
import Faq from "../components/Faq";
import { YtVideo as YoutubeVideo } from "../components/Video";
import SponsorTable from "../components/Sponsors";
import Countdown from "../components/Countdown";
import { Typography, Stack, Card } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CarpenterIcon from "@mui/icons-material/Carpenter";
import PlumbingIcon from "@mui/icons-material/Plumbing";

// This function will return the date of the first Monday of September
// of the current year. If the current month is after September, it will
// return the first Monday of September of the next year.
function getOrientationStartDate() {
  // Get the first Monday of September.
  // If it's after September, get the first Monday of September next year.
  function getFirstMonday(year, month) {
    let date = new Date(year, month, 1);
    while (date.getDay() !== 1) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  }
  const CurrentDate = new Date();
  const CurrentYear = CurrentDate.getFullYear();
  const CurrentMonth = CurrentDate.getMonth();
  if (CurrentMonth > 8) {
    return getFirstMonday(CurrentYear + 1, 8);
  } else {
    return getFirstMonday(CurrentYear, 8);
  }
}

const Home = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{
        ".MuiTypography-root": {
          paddingLeft: "1em",
          paddingRight: "1em",
          maxWidth: "1000px",
        },
      }}
    >
      <Countdown deadline={getOrientationStartDate().toISOString()} />
      <Typography variant="h2" align="center">
        Get Hyped!
      </Typography>
      <Typography variant="h5" align="justify">
        You have made the decision to enroll in the greatest engineering
        university in Canada. With a staggering attendance of over 2,000
        students in this event, are you ready for your first week?
      </Typography>
      <YoutubeVideo
        title="Orientation Week 2024 - Theme Teaser"
        //url="https://www.youtube.com/embed/TMu7yyowrdI"
        url="https://youtube.com/embed/3VlwJSOFcc4"
      />
      <Faq />
      <SponsorTable />
    </Stack>
  );
};

export default Home;
