import React from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
import BlockIcon from "@mui/icons-material/Block";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import ClearIcon from "@mui/icons-material/Clear";

import TheTool from "../util/theToolText.js";

const Error = () => {
  const userCookieName = process.env.REACT_APP_USER_COOKIE_NAME;
  const [cookies] = useCookies([userCookieName]);
  const userData = cookies[userCookieName];
  const { error } = useParams();

  const returnHome = () => {
    window.location.href = "/";
  };

  const mailto = `mailto:engorientation@uwaterloo.ca?subject=Website User Experiencing Error ${error}`;
  var headerIcon;
  var errorMessage;
  switch (String(error)) {
    case "404":
      headerIcon = <SearchIcon fontSize="inherit" />;
      errorMessage = (
        <p>
          Seems you are trying to access a page that doesnt exist. If you were
          sent here by some other page of the website, please{" "}
          <a href={mailto}>let us know</a>! We will fix it as soon as possible.
          For now, head back to the Home page to continue your Orientation
          experience!"
        </p>
      );
      break;
    case "401":
    case "401-no-access":
      headerIcon = <BlockIcon fontSize="inherit" />;
      errorMessage = (
        <p>
          Seems you are trying to access a page that you do not have access to.
          If you think this is an error, please <a href={mailto}>let us know</a>
          ! We will fix it as soon as possible. For now, head back to the Home
          page to continue your Orientation experience!
        </p>
      );
      break;
    case "401-no-user":
      headerIcon = <NoAccountsIcon fontSize="inherit" />;
      errorMessage = (
        <p>
          We could not find your WATiam in our database. Make sure you have
          registered for Orientation! It may take a little while for your
          registration to be processed. If you are unsure how to register, talk
          to a leader or email us at{" "}
          <a href={mailto}>engorientation@uwaterloo.ca</a>. For now, head back
          to the Home page to continue your Orientation experience!
        </p>
      );
      break;
    default:
      headerIcon = <ClearIcon fontSize="inherit" />;
      errorMessage = (
        <p>
          Oh dear, the error code {error} is not recognized. Please let us know
          by emailing us at <a href={mailto}>engorientation@uwaterloo.ca</a>. We
          will fix it as soon as possible. Until then, head back to the Home
          page to continue your Orientation experience!
        </p>
      );
      break;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Stack
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ padding: "1em", maxWidth: "1000px" }}
      >
        <Card
          sx={{
            color: "error.contrastText",
            backgroundColor: "error.main",
            padding: "1em",
            textAlign: "center",
            width: "90%",
            maxWidth: "600px",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{
              fontSize: "4em",
            }}
          >
            <span>{headerIcon}</span>
            <Typography variant="h3" align="center">
              Oops! Dropped Our Screwdriver
            </Typography>
            <span>{headerIcon}</span>
          </Stack>
        </Card>

        <Typography variant="h3" align="center" sx={{ fontSize: "2em" }}>
          Error Code: {error}
        </Typography>

        <Typography variant="h5" align="justify">
          {errorMessage}
        </Typography>

        <Stack item sx={{ paddingBottom: "5em" }}>
          <Button
            variant="contained"
            color="engSecondary"
            onClick={returnHome}
            startIcon={<HomeIcon fontSize="1.2em" />}
            endIcon={<SendIcon fontSize="1.2em" />}
          >
            <Typography
              variant="button"
              sx={{ fontSize: "1.2em" }}
              align="center"
            >
              Return to the Home Page
            </Typography>
          </Button>
        </Stack>

        <Typography variant="h5" align="justify">
          Sorry for the inconvenience! Hopefull you can derive some joy from
          this picture of our beloved engineering mascot, <TheTool />.
        </Typography>

        <img
          src="/images/tool-b&w-2024.jpg"
          alt="Tool"
          style={{
            width: "100%",
            borderRadius: "30px",
          }}
        />
      </Stack>
    </Box>
  );
};

export default Error;

//      {userData ? <p>Logged in as: {userData.watiam}</p> : <div/>}
