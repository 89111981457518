import React from "react";
import { Box, Stack, Button, Typography } from "@mui/material";

import WaterlooLogo from "../../util/WaterlooLogo";

const quick_links = [
  {
    name: "Portal",
    image: "/images/resource-logos/portal.png",
    image_alt: "UWaterloo Portal Logo",
    link: "https://portal.uwaterloo.ca/",
    blurb:
      "University of Waterloo Portal is an excellent resource for students to access their schedules, WatCard info, campus maps, and wellness resources!",
  },
  {
    name: "Learn",
    image: "/images/resource-logos/learn.png",
    image_alt: "UWaterloo Learn Logo",
    link: "https://learn.uwaterloo.ca/",
    blurb:
      "Learn is the University of Waterloo's online learning management system. This is where all of your course resources will be such as lecture slides, assignments, digital submissions, and grades.",
  },
  {
    name: "Quest",
    image: "/images/resource-logos/quest.png",
    image_alt: "UWaterloo Quest Logo",
    link: "https://uwaterloo.ca/quest/",
    blurb:
      "Quest is the University of Waterloo's student information system. This is where you can view your course selection, final term grades, financial information, and other admin functions.",
  },
  {
    name: "Odyssey",
    icon: <WaterlooLogo style={{ maxHeight: "40px" }} />,
    link: "https://odyssey.uwaterloo.ca/",
    blurb:
      "Odyssey is the University of Waterloo's exam management system. Information for your midterms and finals will be available on Odyssey, such as: Time, location, assigned seat, and permitted materials.",
  },
  {
    name: "Waterloo Works",
    image: "/images/resource-logos/waterloo-works.png",
    image_alt: "UW Waterloo Works Logo",
    link: "https://waterlooworks.uwaterloo.ca/home.htm",
    blurb:
      "Waterloo Works is the University of Waterloo's co-op job board. This is where you will apply to co-op jobs and manage your job applications.",
  },
  {
    name: "Engineering Course Calendar",
    icon: <WaterlooLogo style={{ maxHeight: "40px" }} />,
    link: "https://ugradcalendar.uwaterloo.ca/group/uWaterloo-Faculty-of-Engineering",
    blurb:
      "The Engineering Course Calendar is where you can find information on all of the courses offered by the Faculty of Engineering as well as your department's course requirements to graduate.",
  },
  {
    name: "Campus Wellness",
    icon: <WaterlooLogo style={{ maxHeight: "40px" }} />,
    link: "https://uwaterloo.ca/campus-wellness/",
    blurb:
      "Campus Wellness is the University of Waterloo's health and wellness resource. This is where you can find information on mental health, physical health, and other wellness aspects.",
  },
  {
    name: "WUSA Homepage",
    image: "/images/resource-logos/wusa.png",
    image_alt: "WUSA Logo",
    link: "https://wusa.ca/",
    blurb:
      "The Waterloo Undergraduate Student Association (WUSA) is the official student union for undergraduate students at the University of Waterloo. This is where you can find information on student clubs, events, and other WUSA-provided resources.",
  },
  {
    name: "EngSoc Homepage",
    image: "/images/resource-logos/engsoc.png",
    image_alt: "EngSoc Logo",
    link: "https://www.engsoc.uwaterloo.ca/",
    blurb:
      "The Engineering Society (EngSoc) is the student society for engineering students at the University of Waterloo. This is where you can find information on engineering student clubs, events, and other EngSoc-provided resources.",
  },
];

const QuickLinks = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Stack
        justifyContent="center"
        alignItems="stretch"
        sx={{ width: "100%", paddingBottom: "2em" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "2em", backgroundColor: "engSecondary.light" }}
        >
          <Stack justifyContent="center" aligntItems="center">
            <Typography variant="h3" align="center">
              Quick Links
            </Typography>
            <Typography
              variant="h5"
              align="center"
              style={{ maxWidth: "700px" }}
            >
              As you get acquainted with campus and its vast resources, here are
              some of the most important resources that you have at your
              disposal!
            </Typography>
          </Stack>
        </Box>
        {quick_links.map((link, index) => (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              padding: "2em",
              backgroundColor:
                index % 2 == 0 ? "#ffffff" : "engSecondary.light",
            }}
          >
            <Stack justifyContent="center" aligntItems="center" spacing={1}>
              <Typography variant="h3" align="center">
                {link.name}
              </Typography>
              <Typography
                variant="h5"
                align="center"
                style={{ maxWidth: "800px" }}
              >
                {link.blurb}
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="engPrimary"
                  onClick={() => window.open(link.link, "_blank")}
                  sx={{
                    fontSize: "1.25em",
                    padding: "0.5em",
                    minWidth: "300px",
                  }}
                >
                  <Stack>
                    <p>Go to {link.name}</p>
                    {!!link.icon ? (
                      link.icon
                    ) : (
                      <Box display="flex" justifyContent="center">
                        <img
                          src={link.image}
                          alt={link.image_alt}
                          style={{
                            maxHeight: "60px",
                            maxWidth: "250px",
                            objectFit: "contain",
                          }}
                        />
                      </Box>
                    )}
                  </Stack>
                </Button>
              </Box>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default QuickLinks;
