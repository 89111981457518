// - Central Schedule
// - AEDs
// - Eye Wash stations
// - Water Fountains
// - Accessibility Map

// - Basic Emergency Flow Chart
// - Emergency Contact List (Oteam, Robin, Maryl Norris, Emergency Services)

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import axios from "axios";
import toast from "react-simple-toasts";

import LoadingContent from "../components/loading";
import getUserData from "../util/getUserData";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Box,
  Card,
  CardMedia,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";

import UWIcon from "../util/UwIcon";
import MapIcon from "@mui/icons-material/Map";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import ShowerIcon from "@mui/icons-material/Shower";
import RateReviewIcon from "@mui/icons-material/RateReview";
import Diversity1Icon from "@mui/icons-material/Diversity1";

const quick_resources = [
  {
    title: "General Campus Map",
    description:
      "Campus map of building, parking, and other important locations",
    image: "../images/leader-resources/campus-map-qr.png",
    link: "https://uwaterloo.ca/map/",
    link_text: "Download the Campus Map",
    link_icon: <MapIcon />,
  },
  {
    title: "Accessibility Map",
    description:
      "Map of accessible entrances, elevators, and washrooms on campus",
    image: "../images/leader-resources/accessibility-map-qr.png",
    image_alt: "Qr Code for Accessibility Map",
    image_clickable: true,
    image_round: "10px",
    link: "https://uwaterloo.ca/accessibility/getting-around/building-accessibility",
    link_text: "See the Accessibility Map",
    link_icon: <MapIcon />,
  },
  {
    title: "Central Orientation Schedule",
    description: "General Schedule of all Orientation Week Events",
    image: "./images/leader-resources/orientation_schedule_2024.png",
    image_alt: "Central Orientation Schedule",
    image_clickable: true,
    // image_round: "",
    link: "https://docs.google.com/spreadsheets/d/191BJFkY_f-Ykw7DNFqGdN-Mtd0Xbw-dNyBJoEQJZN-Q/edit?gid=0#gid=0",
    link_text: "View the Image",
    link_icon: <CalendarMonthIcon />,
  },
  {
    title: "AEDs on Campus",
    description:
      "List of locations of Automated External Defibrillators (AEDs) on campus",
    // image: "",
    link: "https://uwaterloo.ca/safety-office/emergency-procedures/first-aid/automated-external-defibrillators-aeds/automated-external-defibrillators-aeds-locations",
    link_text: "See list of AED Locations",
    link_icon: <MonitorHeartIcon />,
  },
  {
    title: "Eye Wash Stations and Emergency Showers",
    description:
      "List of locations of Eye Wash Stations and Emergency Showers on campus",
    // image: "",
    link: "https://uwaterloo.ca/safety-office/laboratory-safety/facilities-equipment-and-laboratory-apparatus/eyeface-washes-and-emergency-showers",
    link_text: "List of Eye Wash & Emergency Showers",
    link_icon: <ShowerIcon style={{ transform: "rotate(135deg)" }} />,
  },
  {
    title: "Eng Orientation Feedback Form",
    description:
      "Form for Leaders to provide feedback on their Orientation experience",
    // image: "",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdqXdfpY5L075eaxaRliGorFp5EpHg98rEQ1LldlFqqd1vjUw/viewform?usp=pp_url",
    link_text: "Provide Feedback",
    link_icon: <RateReviewIcon />,
  },
  {
    title: "Eng Orientation Carebear Form",
    description:
      "Form for Leaders to acknowledge any outstanding leaders during the Week. Call out our amazing leaders!",
    // image: "",
    link: "https://bit.ly/2024CareBears",
    link_text: "Submit a Carebear Cuddle",
    link_icon: <Diversity1Icon />,
  },
];

const emergency_contacts = [
  {
    name: "Carson Bay",
    title: "Eng O-Team",
    // affiliation: "",
    image: "/images/leader-resources/headshots/Carson-Bay.jpg",
    // image_dont_round: true,
    contact_info: [
      {
        method: "Email",
        value: "cbay@uwaterloo.ca",
      },
      {
        method: "Phone",
        value: "(250) 863-1848",
      },
    ],
  },
  {
    name: "Carter Ibach",
    title: "Eng O-Team",
    // affiliation: "",
    image: "/images/leader-resources/headshots/Carter-Ibach.png",
    // image_dont_round: true,
    contact_info: [
      {
        method: "Email",
        value: "cwibach@uwaterloo.ca",
      },
      {
        method: "Phone",
        value: "(613) 462-4384",
      },
    ],
  },
  {
    name: "Kerwin Ho",
    title: "Eng O-Team",
    // affiliation: "",
    image: "/images/leader-resources/headshots/Kerwin-Ho.jpg",
    // image_dont_round: true,
    contact_info: [
      {
        method: "Email",
        value: "kerwin.ho@uwaterloo.ca",
      },
      {
        method: "Phone",
        value: "(647) 236-2388",
      },
    ],
  },
  {
    name: "Victoria Smyth",
    title: "Eng O-Team",
    // affiliation: "",
    image: "/images/leader-resources/headshots/Victoria-Smyth.png",
    // image_dont_round: true,
    contact_info: [
      {
        method: "Email",
        value: "esmyth@uwaterloo.ca",
      },
      {
        method: "Phone",
        value: "(249) 878-3540",
      },
    ],
  },
  {
    name: "Robin Jardin",
    title: "Engineering Orientation Coordinator",
    affiliation: "Student Success Office",
    image: "/images/inclusivity-wellness/headshots/Robin-Jardin.jpg",
    // image_dont_round: true,
    contact_info: [
      {
        method: "Email",
        value: "rjardin@uwaterloo.ca",
      },
      {
        method: "Phone",
        value: "(226) 220-8352",
      },
    ],
  },
  {
    name: "Meryl MacDonald",
    title: "New Student Transition Specialist",
    affiliation: "Student Success Office",
    image: "/images/uw-crest.png",
    // image_dont_round: true,
    contact_info: [
      {
        method: "Email",
        value: "manorris@uwaterloo.ca",
      },
      {
        method: "Phone",
        value: "(519) 888-4300",
      },
    ],
  },
  {
    name: "Ashley McKnight",
    title: "New Student Transition Manager",
    affiliation: "Student Success Office",
    image: "/images/uw-crest.png",
    // image_dont_round: true,
    contact_info: [
      {
        method: "Email",
        value: "ajmcknight@uwaterloo.ca",
      },
      {
        method: "Phone",
        value: "(519) 888-4300",
      },
    ],
  },
];

const InfoCard = (card) => {
  return (
    <Card
      sx={{
        padding: "0.5em",
        borderRadius: "1em",
        borderColor: "engSecondary.light",
        border: "1px solid",
        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
      }}
    >
      <Grid
        container
        spacing={0}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item xs={12} sm={4} md={3} sx={{ padding: "0.5em" }}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <CardMedia
              component="img"
              image={
                card.image
                  ? card.image
                  : "../images/inclusivity-wellness/headshots/default.png"
              }
              alt={card.image_alt}
              sx={{
                width: "100%",
                maxWidth: "200px",
                padding: "0.5em",
                borderRadius: "1em",
                backgroundColor: "engSecondary.light",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9} sx={{ padding: "0.5em" }}>
          <Stack spacing={2} justifyContent="space-evenly">
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              align="center"
            >
              {card.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              {card.title}
              <br />
              {card.affiliation}
            </Typography>
            <Divider />
            <Stack spacing={1} justifyContent="space-evenly">
              {card.contact_info.map((info) => (
                <Stack direction="row" spacing={1} justifyContent="flex-start">
                  <Typography variant="body2" color="text.secondary">
                    {info.alt_name ? info.alt_name : info.method}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {info.method === "Email" ? (
                      <a href={"mailto:" + info.value}>{info.value}</a>
                    ) : info.method === "Phone" ? (
                      <a
                        href={
                          "tel:" +
                          info.value
                            .replace(/ |\(|\)|-/g, "")
                            .replace("ext.", ",")
                        }
                      >
                        {info.value}
                      </a>
                    ) : (
                      info.method ===
                      "Web"(
                        <a href={info.value}>
                          {info.link_text ? info.link_text : info.value}
                        </a>
                      )
                    )}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

const LeaderResources = () => {
  const [userData, setUserData] = useState({});
  const [expanded, setExpanded] = React.useState(null);

  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    getUserData(setUserData);
  }, []);

  return (
    <Box display="block" sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "1em" }}
        >
          <Typography variant="h2" align="center">
            Leader Resources
          </Typography>

          {/* Quick Resources List */}

          <div>
            {quick_resources.map((rsrc, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleExpandChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  color="engSecondary.light"
                >
                  <Typography variant="h5">{rsrc.title}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Stack justifyContent="center" alignItems="center">
                    {rsrc.image ? (
                      <Card
                        onClick={() => {
                          if (rsrc.image_clickable) window.open(rsrc.link);
                        }}
                        sx={{
                          borderRadius: "1em",
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={rsrc.image}
                          alt={rsrc.image_alt}
                          sx={{
                            width: "100%",
                            maxWidth: "400px",
                            padding: "0.5em",
                            borderRadius: "1em",
                            backgroundColor: "engSecondary.light",
                          }}
                        />
                      </Card>
                    ) : null}
                    <Typography variant="body1" align="center">
                      {rsrc.description}
                    </Typography>
                    {!!rsrc.link ? (
                      <Box display="flex" justifyContent="center">
                        <Button
                          variant="contained"
                          color="engSecondary"
                          onClick={() => window.open(rsrc.link)}
                          style={{ fontSize: "1em" }}
                          endIcon={rsrc.link_icon ? rsrc.link_icon : null}
                        >
                          {rsrc.link_text}
                        </Button>
                      </Box>
                    ) : null}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>

          {/* --- Emergency Contacts --- */}
          <section id="emergency-contacts" />
          <Typography variant="h2" align="center">
            Emergency Contacts
          </Typography>
          <Box display="flex" justifyContent="center">
            <Grid container spacing={2} justifyContent="center">
              {emergency_contacts.map((contact) => (
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <InfoCard {...contact} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default LeaderResources;
