import React from "react";
import { useCookies } from "react-cookie";
import { Box, Stack, Button, Typography, Grid, Divider } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";

const Maps = () => {
  return (
    <div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack
          justifyContent="center"
          alignItems="stretch"
          sx={{ width: "100%", paddingBottom: "2em" }}
        >
          <Stack
            sx={{ backgroundColor: "engSecondary.light", color: "#000000" }}
            justifyContent="center"
            aligntItems="stretch"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ padding: "3em" }}
            >
              <Stack justifyContent="center" alignItems="center" spacing={2}>
                <img
                  src="../images/uw-crest.png"
                  style={{ maxHeight: "100px" }}
                />
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="h2" align="center">
                    Our Waterloo Campus
                  </Typography>
                </Stack>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ maxWidth: "800px" }}
                >
                  Navigating a large university campus like Waterloo can be an
                  exciting adventure. With over 30 different buildings spread
                  across the campus, each housing different faculties and
                  departments, it's important to have a guide to make sure you
                  don't get lost!
                </Typography>
                <Button
                  variant="contained"
                  color="engPrimary"
                  endIcon={<MapIcon sx={{ fontSize: "1.25em" }} />}
                  onClick={() => window.open("https://uwaterloo.ca/map/")}
                  sx={{ fontSize: "1.25em" }}
                >
                  View our Campus Map
                </Button>
              </Stack>
            </Box>
          </Stack>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h3" align="center" gutterBottom>
              Washroom Locations on Waterloo Campus
              <Divider orientation="horizontal" />
            </Typography>
          </Box>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box sx={{ maxWidth: "430px" }}>
                <Stack justifyContent="center" alignItems="center" spacing={1}>
                  <Typography variant="h4" align="center">
                    Engineering Accessible Washrooms
                  </Typography>
                  <Typography variant="body1" align="center">
                    Looking for Wheelchair Accessible or Gender Inclusive
                    washrooms? This is your best resource for finding washrooms
                    inside the Engineering buildings.
                  </Typography>
                  <Button
                    variant="contained"
                    color="engSecondary"
                    onClick={() =>
                      window.open(
                        "https://uwaterloo.ca/engineering-equity-diversity/washroom-accessibility-0"
                      )
                    }
                    sx={{ fontSize: "1.00em" }}
                  >
                    Find a Washroom in Eng Buildings
                  </Button>
                </Stack>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ maxWidth: "430px" }}>
                <Stack justifyContent="center" alignItems="center" spacing={1}>
                  <Typography variant="h4" align="center">
                    Campus All Gender Washrooms
                  </Typography>
                  <Typography variant="body1" align="center">
                    Outside the Engineering buildings, there are plenty of
                    inclusive washrooms too! Check out the comprehensive
                    campus-wide All-Gender Washroom list for convenient and
                    accessible spaces regardless of your gender identity.
                  </Typography>
                  <Button
                    variant="contained"
                    color="engSecondary"
                    onClick={() =>
                      window.open(
                        "https://uwaterloo.ca/equity-diversity-inclusion-anti-racism/washrooms-list"
                      )
                    }
                    sx={{ fontSize: "1.00em" }}
                  >
                    Find a Washroom around Campus
                  </Button>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </div>
  );
};

export default Maps;
