import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import React, { Component } from "react";

const faqs = [
  {
    question: "What is Engineering Orientation Week?",
    answer:
      "Engineering Orientation is a time to be introduced to the Faculty of Engineering and learn about all it has to offer. From meeting new friends and learning about campus to building wild engineering contraptions - Engineering OWeek is a time to grow, learn, and become a part of the UWaterloo community!",
  },
  {
    question: "When is Fall 2024 Engineering Orientation taking place?",
    answer:
      "Engineering Orientation takes place from August 30th to September 7th, staring with move-ins and orientation check-in on September 1st and ending with ScavEng and the Engineering OWeek Concert on September 7th",
  },
  {
    question: "What kind of events can I expect to see?",
    answer:
      "There will be a large variety of events from earning your hard hat, to challenging your engineering thinking, to an Engineering scavenger hunt. There will be a wide variety of programming available for everyone to contribute to the teams success, regardless of interests! Check Portal for your schedule and more information on the events.",
  },
  {
    question: "Will I be in a group?",
    answer:
      "Yes! You will be in one of 18 colour groups. These groups will be made up of people in your program (including leaders) and stream as well as other programs! Check your Profile close to orientation to see your colour team!",
  },
  {
    question:
      "How do I get a copy of the schedule of the week or a description of the events?",
    answer:
      "Check Portal for the full schedule of orientation events. Head to the Waterloo 101 - Quick-Links page to get onto Portal quickly.",
    link: "/resources/quick-links",
    linkText: "Quick-Links Page",
  },
  {
    question: "What if I want information on my colour group?",
    answer:
      "Check your profile page! Once you sign in with your Waterloo credentials, you will be able to see your info, the location of your colour team's HQ, and the list of leaders on your colour team!",
  },
  {
    question: "Is attendance mandatory at all events?",
    answer:
      "No, but you totally should go to them. Orientation week is a great way to make new friends and have tons of fun. It's an unmatched opportunity to meet people in your program, learn a lot about our Engineering program, the University of Waterloo, and campus life.",
  },
  {
    question: "If I have a question about Orientation Week, who can I ask?",
    answer:
      "You can always message your questions to your colour group's Instagram page and your leaders will be happy to answer them. Also, feel free to reach out to Engineering Orientation Organizers (O-Team) or cross campus orientation at engoteam@uwaterloo.ca.",
  },
];

function Faq() {
  const [expanded, setExpanded] = React.useState(null);

  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div style={{ maxWidth: "1200px" }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h3" color="engPrimary" align="center" gutterBottom>
          Frequently Asked Questions
        </Typography>
      </Box>
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleExpandChange(`panel${index}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            color="engSecondary"
          >
            <Typography variant="h6">{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">{faq.answer}</Typography>
            {faq.link && (
              <div display="flex" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="engSecondary"
                  onClick={() => (window.location.href = faq.link)}
                  endIcon={<ArrowOutwardIcon />}
                >
                  {faq.linkText || "Learn More"}
                </Button>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default Faq;
