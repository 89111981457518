import React from "react";
import { useCookies } from "react-cookie";
import toast from "react-simple-toasts";
import TheTool from "../util/theToolText.js";
import {
  Typography,
  Box,
  Button,
  Card,
  Stack,
  Divider,
  Grid,
} from "@mui/material";

import UWIcon from "../util/UwIcon";

const bookDownloadLinks = {
  2024: "https://bit.ly/UWBook2024",
  2023: "https://drive.google.com/uc?export=download&id=1P9Xm-DLZXpH_nj2WJZI6FV_vth8sIJpI",
  2021: "https://drive.google.com/uc?export=download&id=1FuPE2YAUWGoemxqH2exROOJDjZbK5Tog",
  2020: "https://drive.google.com/uc?export=download&id=16xEnPSM3kqRNAGxZtsrU8l1ykTBQKLqB",
  // "2019" : "???",
  2018: "https://drive.google.com/uc?export=download&id=1GFJFgh2-xT7xaaaizyWOW_eDxEuUWk5F",
};

const currentYear = Object.keys(bookDownloadLinks)
  .map((year) => parseInt(year))
  .sort((a, b) => b - a)[0];

const About = () => {
  const userCookieName = process.env.REACT_APP_USER_COOKIE_NAME;
  const [cookies] = useCookies([userCookieName]);
  const userData = cookies[userCookieName];

  const download_book = (year) => {
    if (bookDownloadLinks[year] == undefined) {
      toast(
        "Sorry, seems there was an error. Cannot find download link for year " +
          year,
        8000
      );
      return;
    }
    window.location.href = bookDownloadLinks[year];
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box style={{ padding: "1em", paddingTop: "0", maxWidth: "1000px" }}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Typography variant="h2" align="center">
            Waterloo Engineering "The Book"
          </Typography>

          <Typography variant="b2" align="justify">
            <p>
              "The Book" is a tradition maintained by the Engineering
              Orientation Leaders. Different versions of this literature have
              been made from the very first year of the Engineering program. Its
              a tradition that outdates <TheTool />, and the formation of the
              University of Waterloo itself!
            </p>
            <p>
              The Book contains tons of useful information about Waterloo
              Engineering culture, such as: Engineering Groups, Design Team
              Groups, Course Lists, Traditions, Lore, and much much more! In
              fact, it's so chock-full that we can't preview here, you'll have
              to download it to view it!
            </p>
          </Typography>

          <Card
            sx={{
              width: "80%",
              maxWidth: "400px",
              borderRadius: "30px",
              cursor: "pointer",
              "&:hover": {
                boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.75)",
              },
            }}
            onClick={() => download_book(currentYear)}
          >
            <img
              src={"images/the-book-" + currentYear + "-cover.jpg"}
              alt={"The Book " + currentYear + " Cover Page"}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Card>

          <Button
            color="engSecondary"
            variant="contained"
            onClick={() => download_book(currentYear)}
            sx={{ fontSize: "1.2em" }}
            startIcon={<UWIcon icon_name="BOOK" font_size="3em" />}
            endIcon={
              <UWIcon icon_name="BOOK" font_size="3em" className="mirror" />
            }
          >
            Download The {currentYear} Book
          </Button>

          <Divider direction="horizontal" sx={{ width: "100%" }} />

          <Typography variant="h3" align="center">
            Past Books
          </Typography>
          <Typography variant="b2" align="left">
            Here are some of the past books that we have been able to find. If
            you have a copy of a book that we are missing, please contact us at{" "}
            <a href="mailto:engoteam@uwaterloo.ca" sx={{ color: "engPrimary" }}>
              engoteam@uwaterloo.ca
            </a>
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {Object.keys(bookDownloadLinks)
              .sort((a, b) => b - a)
              .filter((e) => e !== currentYear)
              .map((year) => (
                <Grid item xs={6} sm={4} md={3}>
                  <Button
                    color="engSecondary"
                    variant="contained"
                    onClick={() => download_book(year)}
                    sx={{ fontSize: "1em" }}
                    startIcon={<UWIcon icon_name="BOOK" font_size="2em" />}
                  >
                    The {year} Book
                  </Button>
                </Grid>
              ))}
          </Grid>
        </Stack>
      </Box>
    </Box>
  );
};

export default About;
