import React from "react";
import "./sponsor.css";
import {
  Box,
  Card,
  Divider,
  Grid,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

const sponsors = [
  {
    name: "EngSoc",
    logoPath: "/images/sponsors/gold/engsoc.png",
    text: null,
    size: "big",
  },
  {
    name: "Mathematics Endowment Fund (MEF)",
    logoPath: "/images/sponsors/gold/mef.png",
    text: null,
    size: "big",
  },
  {
    name: "Waterloo Engineering Endowment Fund (WEEF)",
    logoPath: "/images/sponsors/gold/weef.png",
    text: null,
    size: "big",
  },
  {
    name: "Waterloo Undergraduate Student Association (WUSA)",
    logoPath: "/images/sponsors/gold/wusa.png",
    text: null,
    size: "big",
  },
  {
    name: "Buttcon Limited",
    logoPath: "/images/sponsors/gold/buttcon.png",
    text: null,
    size: "big",
  },
  {
    name: "IMHOTEP",
    logoPath: "/images/sponsors/gold/imhotep.png",
    text: null,
    size: "big",
  },
  {
    name: "Mel's Diner",
    logoPath: "/images/sponsors/gold/mels.jpg",
    text: null,
    size: "big",
  },
  {
    name: "Toyota Motor Manufacturing Canada (TMMC)",
    logoPath: "/images/sponsors/gold/tmmc.png",
    text: null,
    size: "big",
  },
  {
    name: "Paradigm Transportation Solutions Limited",
    logoPath: "/images/sponsors/silver/paradigm.png",
    text: null,
    size: "medium",
  },
  {
    name: "AGF Rebar",
    logoPath: "/images/sponsors/silver/agfrebar.png",
    text: null,
    size: "medium",
  },
  {
    name: "Ontario Society of Professional Engineers (OSPE)",
    logoPath: "/images/sponsors/silver/ospe.png",
    text: null,
    size: "medium",
  },
  {
    name: "Professional Engineers Ontario (PEO)",
    logoPath: "/images/sponsors/silver/peo.png",
    text: null,
    size: "medium",
  },
  {
    name: "Sika",
    logoPath: "/images/sponsors/silver/sika.jpg",
    text: null,
    size: "medium",
  },
  {
    name: "CEM Engineering",
    logoPath: "/images/sponsors/silver/cem.png",
    text: null,
    size: "medium",
  },
  {
    name: "Alumni: Old FOC and Friends",
    logoPath: "/images/sponsors/silver/claudia.png",
    text: null,
    size: "medium",
  },
];

const divider = () => {
  return (
    <Grid item xs="12">
      <Divider sx={{ backgroundColor: "engPrimary.main" }} />
    </Grid>
  );
};

const SponsorTable = () => {
  // Tooltips can be opened by hover or click.
  // But we only want to show one at a time.
  const [sponsorsTooltip, setSponsorsTooltip] = React.useState("");

  const handleTooltipOpen = (sponsor) => () => {
    setSponsorsTooltip(sponsor);
  };

  const handleTooltipClose = (sponsor) => () => {
    setSponsorsTooltip((sponsorsTooltip) =>
      sponsorsTooltip === sponsor ? "" : sponsorsTooltip
    );
  };

  return (
    <Box
      display="block"
      sx={{ width: "100%", maxWidth: "1000px", padding: "0" }}
    >
      <Stack sx={{ padding: "2em" }}>
        <Box display="flex" justifyContent="center">
          <Typography variant="h3" align="center">
            Our Lovely Sponsors
          </Typography>
        </Box>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ pt: "1rem", pb: "2rem" }}
        >
          {[
            // Leading Divider
            <Grid item xs={12}>
              <Divider sx={{ backgroundColor: "engPrimary.dark" }} />
            </Grid>,
            // Grid of Sponsorship Logos
            ...["huge", "big", "medium", "small"].map((size) => (
              <>
                {sponsors
                  .filter((sponsor) => sponsor.size === size)
                  .map((sponsor) => {
                    var size_dict;
                    switch (size) {
                      case "huge":
                        size_dict = { xs: 12, md: 6, lg: 4 };
                        break;
                      case "big":
                        size_dict = { xs: 6, md: 4, lg: 3 };
                        break;
                      case "medium":
                        size_dict = { xs: 4, md: 3, lg: 2 };
                        break;
                      case "small":
                        size_dict = { xs: 3, md: 2, lg: 1 };
                        break;
                      default:
                        size_dict = { xs: 3, md: 2, lg: 1 };
                        break;
                    }
                    return (
                      <Grid item {...size_dict} key={sponsor.name}>
                        <Box className="logo-container">
                          <Card elevation={0} className="responsive-logo">
                            <Tooltip
                              title={sponsor.name}
                              enterDelay={500}
                              leaveDelay={200}
                              disableTouchListener
                              TransitionComponent={Zoom}
                              open={sponsorsTooltip === sponsor.name}
                              onOpen={handleTooltipOpen(sponsor.name)}
                              onClose={handleTooltipClose(sponsor.name)}
                            >
                              <img
                                src={sponsor.logoPath}
                                alt={sponsor.name}
                                style={{
                                  objectFit: "contain",
                                  display: "block",
                                  height: "100%",
                                  width: "100%",
                                }}
                                onClick={() => {
                                  // Open the tooltip for 3 seconds when clicked.
                                  handleTooltipOpen(sponsor.name)();
                                  setTimeout(
                                    handleTooltipClose(sponsor.name),
                                    3000
                                  );
                                }}
                              />
                            </Tooltip>
                          </Card>
                        </Box>
                      </Grid>
                    );
                  })}
                {sponsors.filter((sponsor) => sponsor.size === size).length >
                0 ? (
                  <Grid item xs="12">
                    <Divider
                      key={size.name}
                      sx={{ backgroundColor: "engPrimary.dark" }}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
              </>
            )),
          ]}
        </Grid>
      </Stack>
    </Box>
  );
};

export default SponsorTable;
