import React from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupIcon from "@mui/icons-material/Groups3";
import LinkIcon from "@mui/icons-material/Link";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PersonIcon from "@mui/icons-material/Person";
import useFitText from "../../util/FitText";

const immediate_resources = [
  {
    header: "Emergency Services",
    contacts: [
      {
        name: "Campus Police",
        image:
          "https://uwaterloo.ca/special-constable-service/sites/ca.special-constable-service/files/uploads/images/c009427-police-logo_fin_0.png",
        image_dont_round: true,
        contact_info: [
          {
            method: "Office",
            value: "Commissary Building (COM)",
          },
          {
            method: "Phone",
            value: "519-888-4911",
          },
          {
            method: "Website",
            alternate_name: "UW Extension: 22222",
            value:
              "https://uwaterloo.ca/special-constable-service/campus-safety/phones-helplines",
          },
          {
            method: "Email",
            value: "uwscs@uwaterloo.ca",
          },
        ],
      },
      {
        name: "Waterloo Regional Police Service",
        image:
          "https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Waterloo_Regional_Police_Logo.svg/800px-Waterloo_Regional_Police_Logo.svg.png",
        image_dont_round: true,
        contact_info: [
          {
            method: "Phone",
            alternate_name: "911 - Emergency",
            value: "911",
          },
          {
            method: "Phone",
            value: "519-570-9777",
          },
          {
            method: "Website",
            value: "https://www.wrps.on.ca",
          },
        ],
      },
    ],
  },
  {
    header: "Racism Response Contacts",
    contacts: [
      {
        name: "Sarah Menzies",
        title: "Anti-Racism Specialist",
        affiliation: "Office of Equity, Diversity, Inclusion and Anti-racism",
        image: "../images/inclusivity-wellness/headshots/Sarah-Menzies.png",
        contact_info: [
          {
            method: "Email",
            value: "s2menzies@uwaterloo.ca",
          },
        ],
      },
      {
        name: "Kwasi Sarfo",
        title: "Associate Director, Anti-racism Response ",
        affiliation: "Office of Equity, Diversity, Inclusion and Anti-racism",
        image: "../images/inclusivity-wellness/headshots/Kwasi-Sarfo.png",
        contact_info: [
          {
            method: "Email",
            value: "kwasi.sarfo@uwaterloo.ca",
          },
        ],
      },
    ],
  },
  {
    header: "General Equity Office Contacts",
    contacts: [
      {
        name: "Equity Office",
        affiliation: "Waterloo Region",
        image: "/images/uw-crest.png",
        contact_info: [
          {
            method: "Email",
            value: "equity@uwaterloo.ca",
          },
        ],
      },
    ],
  },
  {
    header: "Sexual and Gender Violence Contacts",
    contacts: [
      {
        name: "Sexual Assault Support Centre",
        affiliation: "Waterloo Region",
        image: "/images/inclusivity-wellness/SASC-logo.png",
        contact_info: [
          {
            method: "Phone",
            value: "(519) 741 8633",
          },
          {
            method: "Website",
            value: "https://www.sascwr.org/24-hour-support-line.html",
          },
        ],
      },
      {
        name: "Sexual Violence Prevention & Response Office",
        image: "/images/uw-crest.png",
        contact_info: [
          {
            method: "Email",
            value: "svpro@uwaterloo.ca",
          },
          {
            method: "Website",
            value:
              "https://uwaterloo.ca/sexual-violence-prevention-response-office/",
          },
        ],
      },
    ],
  },
  {
    header: "Mental Health Contacts",
    contacts: [
      {
        name: "Good2Talk Helpline",
        title: "Post Secondary Student Helpline",
        affiliation: "Ontario",
        image:
          "https://good2talk.ca/wp-content/uploads/2021/12/on-good2talk-logo.svg",
        image_dont_round: true,
        contact_info: [
          {
            method: "Phone",
            value: "1-866-925-5454",
          },
          {
            method: "Website",
            value: "https://good2talk.ca/ontario/",
          },
        ],
      },
      {
        name: "Here 24/7",
        title: "Mental Health and Addictions Support Line",
        affiliation: "Waterloo - Wellington",
        image: "https://here4hope.ca/wp-content/uploads/2019/11/Here-247.png",
        image_dont_round: true,
        contact_info: [
          {
            method: "Phone",
            value: "1-844-437-3247",
          },
          {
            method: "Website",
            value: "https://here247.ca/",
          },
        ],
      },
      {
        name: "Grand River Hospital",
        title: "Mental Health and Support Info Line",
        affiliation: "Kitchener - Waterloo",
        image: "https://www.grhosp.on.ca/assets/images/interface/logo.svg",
        image_dont_round: true,
        contact_info: [
          {
            method: "Phone",
            value: "519-749-4300",
          },
          {
            method: "Website",
            value:
              "https://www.grhosp.on.ca/care/services-departments/mental-health-addiction",
          },
        ],
      },
      {
        name: "Saint Mary's Hospital",
        title: "General Contact Line",
        affiliation: "Kitchener - Waterloo",
        image:
          "https://www.smgh.ca/work-with-us/careers/get-to-know-waterloo-region/smgh_rgb.png",
        image_dont_round: true,
        contact_info: [
          {
            method: "Phone",
            value: "519-744-3311",
          },
          {
            method: "Website",
            value: "https://www.smgh.ca/",
          },
        ],
      },
    ],
  },
  {
    header: "Friendly Campus Folks",
    contacts: [
      {
        name: "Engineering OTeam",
        title: "Orientation Organizers",
        image: "/images/inclusivity-wellness/headshots/OTeam.jpg",
        contact_info: [
          {
            method: "Email",
            value: "engoteam@uwaterloo.ca",
          },
        ],
      },
      {
        name: "Jazz Fitgerald",
        title: "Manager, Student Equity and Community",
        affiliation: "Student Success Office",
        image: "/images/inclusivity-wellness/headshots/Jazz-Fitzgerald.jpg",
        contact_info: [
          {
            method: "Email",
            displayText: "jazz.fitzgerald @uwaterloo.ca",
            value: "jazz.fitzgerald@uwaterloo.ca",
          },
          {
            method: "Office",
            value: "South Campus Hall (SCH) 236",
          },
        ],
      },
      {
        name: "Robin Jardin",
        title: "Student Relations Officer, Engineering Orientation Coordinator",
        affiliation:
          "Dean's office, Engineering Teaching & Student Experience ",
        image: "/images/inclusivity-wellness/headshots/Robin-Jardin.jpg",
        contact_info: [
          {
            method: "Email",
            value: " rjardin@uwaterloo.ca",
          },
          {
            method: "Office",
            value: "Carl Pollock Hall (CPH) 2376C",
          },
        ],
      },
    ],
  },
];

const follow_up_resources = [
  {
    name: "Equity Office EDI Intake Form",
    image: "/images/uw-crest.png",
    // image_alt: N/A,
    blurb:
      "If you are looking for non-immediate support from the Office of Equity, Diversity, Inclusion and Anti-racism, fill out their form and they can get in contact with you however you want. This is the best way to start a conversation",
    button_text: "📋 Fill out the form",
    button_link:
      "https://uwaterloo.ca/equity-diversity-inclusion-anti-racism/equity-office/edi-r-intake-form",
  },
  {
    name: "UWaterloo Counselling Services",
    image: "/images/uw-crest.png",
    // image_alt: N/A,
    blurb:
      "UWaterloo Counselling Services offers a variety of services to support students' mental health and wellness. They offer individual counselling, same-day counselling, workshops, and wellness programs.",
    button_text: "🖥️ Check out the website",
    button_link: "https://uwaterloo.ca/campus-wellness/counselling-services",
  },
  {
    name: "UWaterloo Health Services",
    image: "/images/uw-crest.png",
    // image_alt: N/A,
    blurb:
      "UWaterloo Health Services offers a variety of things to support students' physical health and wellness. They have a student medical clinic, immunizations, verification of illness services, information on student medical insurance, and more!",
    button_text: "🖥️ Check out the website",
    button_link: "https://uwaterloo.ca/campus-wellness/health-services",
  },
  {
    name: "Indigenous Student Centre",
    image:
      "https://www.bher.ca/sites/default/files/images/2023-05/United%20College%20Logo.jpg",
    image_alt: "United College at University of Waterloo Logo",
    blurb:
      "AANII, SHE:KON, BOOZHOO, HELLO, BONJOUR, TANSI! The Waterloo Indigenous Student Centre (WISC) is a friendly space on campus where you can connect with other Indigenous (First Nation, Inuit, and Metis) students and members of the Kitchener-Waterloo community. WISC is located in United College",
    button_text: "📃 Check out the Information Page",
    button_link:
      "https://uwaterloo.ca/united-college/waterloo-indigenous-student-centre",
  },
  {
    name: "International Student Supports",
    image: "/images/uw-crest.png",
    // image_alt: N/A,
    blurb:
      "The Student Success Office provides tons of information for International Students. Immigration consulting, tax information, health insurance, international peer communities, and more!",
    button_text: "✈️ Check out the resources",
    button_link:
      "https://uwaterloo.ca/student-success/international-student-resources",
  },
  {
    name: "Accessibility Services",
    image: "/images/uw-crest.png",
    // image_alt: N/A,
    blurb:
      "Accessibility Services provides support in form of academic accommodations, learning strategy development, and more!",
    button_text: "📧 access@uwaterloo.ca",
    button_link: "mailto:access@uwaterloo.ca",
  },
];

const ContactMethod = ({ contact }) => {
  const { fontSize: fontSize, ref: fontSizeRef } = useFitText({
    maxSize: 10,
    minSize: 8,
  });

  const displayText =
    contact.method === "Email"
      ? contact.displayText || contact.value
      : contact.value;

  switch (contact.method) {
    case "Website":
      return (
        <Button
          variant="contained"
          color="engPrimary"
          endIcon={<ArrowOutwardIcon />}
          onClick={() => window.open(contact.value, "_blank")}
          sx={{ maxWidth: "100%" }}
        >
          <Box
            display="block"
            justifyContent="center"
            alignItems="center"
            style={{ maxWidth: "100%" }}
          >
            <Typography
              variant="body2"
              align="center"
              ref={fontSizeRef}
              style={{ fontSize }}
            >
              {contact.alternate_name
                ? contact.alternate_name
                : "Get in Contact"}
            </Typography>
          </Box>
        </Button>
      );
    case "Email":
      return (
        <Button
          variant="contained"
          color="engPrimary"
          endIcon={<EmailIcon />}
          onClick={() => window.open(`mailto:${contact.value}`, "_blank")}
        >
          <Box
            display="block"
            justifyContent="center"
            alignItems="center"
            style={{ maxWidth: "100%" }}
          >
            <Typography
              variant="body2"
              align="center"
              ref={fontSizeRef}
              style={{ fontSize }}
            >
              {contact.alternate_name ? contact.alternate_name : displayText}
            </Typography>
          </Box>
        </Button>
      );
    case "Phone":
      return (
        <Button
          variant="contained"
          color="engPrimary"
          endIcon={<PhoneIcon />}
          onClick={() => window.open(`tel:${contact.value}`, "_blank")}
          sx={{ maxWidth: "100%" }}
        >
          <Box
            display="block"
            justifyContent="center"
            alignItems="center"
            style={{ maxWidth: "100%" }}
          >
            <Typography
              variant="body2"
              align="center"
              ref={fontSizeRef}
              style={{ fontSize }}
            >
              {contact.alternate_name ? contact.alternate_name : contact.value}
            </Typography>
          </Box>
        </Button>
      );
    default:
      return (
        <Box
          display="block"
          justifyContent="center"
          alignItems="center"
          style={{ maxWidth: "100%" }}
        >
          <Typography
            variant="body2"
            align="center"
            ref={fontSizeRef}
            style={{ fontSize }}
          >
            {contact.value}
          </Typography>
        </Box>
      );
  }
};

const phoneReg = /(\d{3})(\d{3})(\d{4})/;

//TODO: This is not the best way to start this page. It needs a better way to direct students toward the Inclusivity
//  and Wellness oriented student groups and resources.
const Header = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Stack spacing={2} style={{ maxWidth: "1200px", padding: "1em" }}>
        {/* Header Section with brief descriptors */}
        <Typography variant="h2" align="center" gutterBottom>
          Equity, Diversity, and Inclusivity
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Engineering Orientation along with the University of Waterloo is
          committed to creating a welcoming environment and supporting you in
          your transition to university life. If you need support, have
          concerns/feedback or are having trouble feeling welcome there are
          various resources including immediate resources, counselling, wellness
          activities and many others - we are eager to listen and ready to help.
          <br />
          <br />
          We suggest also looking into our affiliate groups for community
          building and additional support.{" "}
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="engPrimary"
            endIcon={<GroupIcon style={{ fontSize: "1.5em" }} />}
            onClick={() => (window.location.href = "/resources/student-groups")}
            style={{ fontSize: "1em" }}
          >
            See affiliate groups
          </Button>
        </Box>
        <Divider orientation="horizontal" />
        <Typography variant="h2" align="center" gutterBottom>
          Health and Wellness
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          University is a wild, exciting, and potentially stressful experience.
          Make sure you take care of yourself with some of these excellent
          resources. Professionals around campus, in person and remote
          counselling, knowledge bases, and much more are available to you.
        </Typography>
      </Stack>
    </Box>
  );
};

const ImmediateResources = () => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {immediate_resources.map((category, category_index) => (
        <Accordion
          key={category_index}
          expanded={expanded === category.header}
          onChange={handleChange(category.header)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={category.header.replace(/ /g, "-") + "-content"}
            id={category.header.replace(/ /g, "-") + "-header"}
          >
            <Typography align="left">{category.header}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={1}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {category.contacts.map((contact, contact_index) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={contact_index}>
                  <Card
                    sx={{
                      padding: "0.5em",
                      outline: "1px solid #5D0096",
                      borderRadius: ".5rem",
                    }}
                  >
                    <Grid
                      container
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={4}>
                        <Box display="flex" justifyContent="center">
                          {!!contact.image ? (
                            <img
                              src={contact.image}
                              alt={contact.name + " Headshot"}
                              style={{
                                width: "100%",
                                height: "100%",
                                maxheight: "150px",
                                maxWidth: "150px",
                                objectFit: "contain",
                                borderRadius: ".5em",
                              }}
                            />
                          ) : (
                            <PersonIcon
                              style={{
                                color: "#5D0096",
                                fontSize: "150px",
                              }}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Grid container spacing={1} direction="column">
                          <Grid item xs={1}>
                            <Box display="flex" justifyContent="center">
                              <Typography variant="h6" align="center">
                                {contact.name}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Box display="flex" justifyContent="center">
                              <Typography variant="body2" align="center">
                                {contact.title}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <Box display="flex" justifyContent="center">
                              <Typography variant="body2" align="center">
                                {contact.affiliation}
                              </Typography>
                            </Box>
                          </Grid>
                          {contact.contact_info.map((info, info_index) => (
                            <Grid
                              item
                              xs={1}
                              key={info_index}
                              style={{ maxWidth: "100%" }}
                            >
                              <Box display="flex" justifyContent="center">
                                <ContactMethod contact={info} />
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

const FollowUpButton = ({ resource }) => {
  const { fontSize: fontSize, ref: fontSizeRef } = useFitText({
    maxSize: 10,
    minSize: 8,
  });

  return (
    <Button
      variant="contained"
      color="engPrimary"
      endIcon={<ArrowOutwardIcon />}
      onClick={() => window.open(resource.button_link, "_blank")}
      sx={{ maxWidth: "100%" }}
    >
      <Box
        display="block"
        justifyContent="center"
        alignItems="center"
        style={{ maxWidth: "100%" }}
      >
        <Typography
          variant="body2"
          align="center"
          ref={fontSizeRef}
          style={{ fontSize }}
        >
          {resource.button_text}
        </Typography>
      </Box>
    </Button>
  );
};

const FollowUpResources = () => {
  return (
    <div>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        {follow_up_resources.map((resource, index) => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
            <Card
              style={{
                border: "1px solid #5D0096",
                borderRadius: ".5rem",
                padding: "0.5em",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  <Box display="flex" justifyContent="center">
                    <img
                      src={resource.image}
                      alt={resource.image_alt}
                      style={{
                        width: "100%",
                        height: "100%",
                        maxheight: "150px",
                        maxWidth: "150px",
                        objectFit: "contain",
                        borderRadius: ".5em",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={1} direction="column">
                    <Grid item xs={1}>
                      <Typography variant="h6" align="center">
                        {resource.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body2" align="center">
                        {resource.blurb}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Box display="flex" justifyContent="center">
                        <FollowUpButton resource={resource} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const Body = () => {
  const [immediateExpanded, setImmediateExpanded] = React.useState(true);
  const [followUpExpanded, setFollowUpExpanded] = React.useState(true);

  return (
    <div style={{ maxWidth: "1200px" }}>
      {/* EDI Contacts Accordion */}
      <Typography variant="h2" align="center" gutterBottom>
        EDI Contacts
      </Typography>

      {/* Immediate Resources */}
      <Accordion
        expanded={immediateExpanded}
        onChange={() => {
          setImmediateExpanded(!immediateExpanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="Immediate-Resources-header"
        >
          <Box display="block" style={{ width: "100%", height: "100%" }}>
            <Box display="flex" justifyContent="center">
              <Typography
                variant="h3"
                align="center"
                style={{ fontSize: "2.0em", maxWidth: "400px" }}
              >
                Immediate Resources
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <ImmediateResources />
        </AccordionDetails>
      </Accordion>

      {/* Follow Up Resources Accordion */}
      <Accordion
        expanded={followUpExpanded}
        onChange={() => {
          setFollowUpExpanded(!followUpExpanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="Follow-up-Resources-header"
        >
          <Box display="block" style={{ width: "100%", height: "100%" }}>
            <Box display="flex" justifyContent="center">
              <Typography
                variant="h3"
                align="center"
                style={{ fontSize: "2.0em", maxWidth: "400px" }}
              >
                Follow-up Resources
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <FollowUpResources />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const InclusivityWellness = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      style={{ paddingBottom: "4em" }}
    >
      <Header />
      <Body />
    </Stack>
  );
};

export default InclusivityWellness;
