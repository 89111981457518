import { createTheme } from "@mui/material/styles";

export const EngOrientationTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      ssm: 400,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      // Prefer to use engPrimary for brand styling clarity
      main: "#5D0096",
      light: "#7600BF",
      dark: "#3F0066",
      contrastText: "#FFFFFF",
    },
    secondary: {
      // Prefer to use engSecondary for brand styling clarity
      main: "#A05DCB",
      light: "#B270DB",
      dark: "#9139C7",
      contrastText: "#000000",
    },
    engPrimary: {
      main: "#5D0096",
      light: "#7600BF",
      dark: "#3F0066",
      contrastText: "#FFFFFF",
    },
    engSecondary: {
      main: "#A05DCB",
      light: "#B270DB",
      dark: "#9139C7",
      contrastText: "#000000",
    },
    uwPrimary: {
      main: "#FED34C",
      light: "#F2EDA8",
      dark: "#EAAB00",
      contrastText: "#000000",
    },
    uwSecondary: {
      main: "#FAE100",
      light: "#F2EDA8",
      dark: "#EAAB00",
      contrastText: "#000000",
    },
    error: {
      main: "#AC3931",
      light: "#B44B44",
      dark: "#90231C",
      contrastText: "#FFFFFF",
      lightText: "#000000",
    },
    warning: {
      main: "#FAA916",
      light: "#FCC35A",
      dark: "#E59A18",
      contrastText: "#000000",
    },
    info: {
      main: "#2947B6",
      light: "#3C58BD",
      dark: "#0B2789",
      contrastText: "#FFFFFF",
      lightText: "#000000",
    },
    success: {
      main: "#4caf50",
      light: "#63B866",
      dark: "#309B34",
      contrastText: "#000000",
    },
  },
  components: {
    MuiToolbar: {
      defaultProps: {
        disableGutters: true,
        variant: "dense",
      },
      styleOverrides: {
        dense: {
          height: 56,
          minHeight: 56,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        position: "static",
      },
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#000",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "text", color: "engPrimary" },
          style: {
            color: "#5D0096",
            background: "none",
            "&:hover, &:focus ": {
              color: "#3f0066",
            },
          },
        },
        {
          props: { variant: "contained", color: "engPrimary" },
          style: {
            color: "#FFFFFF",
            backgroundColor: "#5D0096",
            "&:hover, &:focus ": {
              backgroundColor: "#3f0066",
            },
          },
        },
        {
          props: { variant: "outlined", color: "engPrimary" },
          style: {
            color: "#5D0096",
            background: "none",
            outline: "1px solid #5D0096",
            "&:hover, &:focus ": {
              backgroundColor: "#3f0066",
              outline: "2px solid #3f0066",
            },
          },
        },
        {
          props: { variant: "text-white", color: "engPrimary" },
          style: {
            color: "#FFFFFF",
            background: "none",
            "&:hover, &:focus ": {
              color: "#5D0096",
            },
          },
        },
        {
          props: { variant: "text", color: "engSecondary" },
          style: {
            color: "#A05DCB",
            background: "none",
            "&:hover, &:focus ": {
              color: "#9139C7",
            },
          },
        },
        {
          props: { variant: "contained", color: "engSecondary" },
          style: {
            color: "#FFFFFF",
            backgroundColor: "#A05DCB",
            "&:hover, &:focus ": {
              backgroundColor: "#9139C7",
            },
          },
        },
        {
          props: { variant: "outlined", color: "engSecondary" },
          style: {
            color: "#A05DCB",
            background: "none",
            outline: "1px solid #A05DCB",
            "&:hover, &:focus ": {
              color: "#9139C7",
              outline: "2px solid #9139C7",
            },
          },
        },
        {
          props: { variant: "text-white", color: "engSecondary" },
          style: {
            color: "#FFFFFF",
            background: "none",
            "&:hover, &:focus ": {
              color: "#A05DCB",
            },
          },
        },
        {
          props: { variant: "text", color: "error" },
          style: {
            color: "#AC3931",
            background: "none",
            "&:hover, &:focus ": {
              color: "#90231C",
            },
          },
        },
        {
          props: { variant: "contained", color: "error" },
          style: {
            color: "#FFFFFF",
            backgroundColor: "#AC3931",
            "&:hover, &:focus ": {
              backgroundColor: "#90231C",
            },
          },
        },
        {
          props: { variant: "outlined", color: "error" },
          style: {
            color: "#AC3931",
            background: "none",
            outline: "1px solid #AC3931",
            "&:hover, &:focus ": {
              color: "#90231C",
              outline: "2px solid #90231C",
            },
          },
        },
        {
          props: { variant: "text", color: "info" },
          style: {
            color: "#2947B6",
            background: "none",
            "&:hover, &:focus ": {
              color: "#0B2789",
            },
          },
        },
        {
          props: { variant: "contained", color: "info" },
          style: {
            color: "#FFFFFF",
            backgroundColor: "#2947B6",
            "&:hover, &:focus ": {
              backgroundColor: "#0B2789",
            },
          },
        },
        {
          props: { variant: "outlined", color: "info" },
          style: {
            color: "#2947B6",
            background: "none",
            outline: "1px solid #2947B6",
            "&:hover, &:focus ": {
              color: "#0B2789",
              outline: "2px solid #0B2789",
            },
          },
        },
      ],
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          outline: "1px solid #5D0096",
          "&:hover, &:focus": {
            outline: "1px solid #3f0066",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          backgroundColor: "#5D0096",
          "&:hover, &:focus": {
            backgroundColor: "#3f0066",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          color: "#000000",
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: "Barlow Condensed",
    },
    h2: {
      fontFamily: "Barlow Condensed",
    },
    h3: {
      fontFamily: "Barlow Condensed",
    },
    h4: {
      fontFamily: "Noto Serif Georgian",
    },
    h5: {
      fontFamily: "Noto Serif Georgian",
    },
    h6: {
      fontFamily: "Noto Serif Georgian",
    },
    subtitle1: {
      fontFamily: "Noto Serif Georgian",
    },
    subtitle2: {
      fontFamily: "Noto Serif Georgian",
    },
    body1: {
      fontFamily: "Noto Serif Georgian",
    },
    body2: {
      fontFamily: "Noto Serif Georgian",
    },
    button: {
      fontFamily: "Verdana",
    },
    buttonSecondary: {
      fontFamily: "Verdana",
      fontSize: "0.70rem",
    },
    caption: {
      fontFamily: "Verdana",
    },
  },
});
