import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Error from "./pages/error";
import Home from "./pages";
import About from "./pages/about";
import TheBook from "./pages/thebook";
import Profile from "./pages/profile";
import Teams from "./pages/teams";
import LeaderResources from "./pages/leader-resources";
import QR from "./pages/qr";
import Footer from "./components/Footer";
import Maps from "./pages/resources/maps";
import MoveIn from "./pages/resources/move-in";
import QuickLinks from "./pages/resources/quick-links";
import InclusivityWellness from "./pages/resources/inclusivity-wellness";
import StudentGroups from "./pages/resources/student-groups";
import { Box } from "@mui/material";

function NotFound() {
  window.location.href = "/frontend-error/404";

  return <Box />;
}

function App({ instance }) {
  return (
    <Router>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/book/*" element={<TheBook />} />
            <Route path="/teams" element={<Teams />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/leader-resources" element={<LeaderResources />} />
            <Route path="/qr/:code" element={<QR />} />
            <Route path="/resources/maps" element={<Maps />} />
            <Route path="/resources/move-in" element={<MoveIn />} />
            <Route path="/resources/quick-links" element={<QuickLinks />} />
            <Route
              path="/resources/inclusivity-wellness"
              element={<InclusivityWellness />}
            />
            <Route
              path="/resources/student-groups"
              element={<StudentGroups />}
            />
            <Route path="/frontend-error/:error" element={<Error />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
