import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import toast from "react-simple-toasts";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";

import "../App.css";
import getUserData from "../util/getUserData";
import LoadingContent from "../components/loading";
import { Card, Stack, Box, Button, Typography } from "@mui/material";
import UWIcon from "../util/UwIcon";

const QrSubmitPage = () => {
  const authCookieName = process.env.REACT_APP_AUTH_COOKIE_NAME;
  const userCookieName = process.env.REACT_APP_USER_COOKIE_NAME;
  const [cookie] = useCookies([authCookieName, userCookieName]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [scanData, setScanData] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const { code } = useParams();

  // Submit the QR code to the database
  const submitQrCode = (code) => {
    axios
      .put(`/api/scan-qr/${code}`, {
        withCredentials: true,
      })
      .then((res) => {
        setScanData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          window.location.href = "/login";
          return;
        } else if (err.response && err.response.status === 500) {
          toast("Sorry, seems there was an internal website issue.");
          return;
        } else {
          setErrorCode(err.response.status);
          setScanData({
            msg: err.response.data?.error || "",
          });
          setLoading(false);
        }
      });
  };

  // Get the user data and submit the QR code at page load
  useEffect(() => {
    getUserData(setUserData);
    submitQrCode(code);
  }, []);

  if (loading) return LoadingContent();
  else
    return (
      <div>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box style={{ width: "80%", maxWidth: "1000px", margin: "0 auto" }}>
            <Card
              sx={{
                backgroundColor: "engSecondary.light",
                color: "engSecondary.contrastText",
                padding: "1rem",
                margin: "1rem",
              }}
            >
              <Stack
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{
                  "MuiCard-root": {
                    borderRadius: "0.5rem",
                    padding: "0.5rem",
                  },
                }}
              >
                {!errorCode ? (
                  // Successful Code Scan
                  <>
                    <Card
                      sx={{
                        color: "success.contrastText",
                        backgroundColor: "success.main",
                      }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <UWIcon
                          icon_name="ROCKET"
                          font_size="50px"
                          sx={{ transform: "rotate(-15deg)" }}
                        />
                        <Typography variant="h3" align="center">
                          QR Code Scanned !
                        </Typography>
                        <UWIcon
                          icon_name="ROCKET"
                          font_size="50px"
                          className="mirror"
                          sx={{ transform: "rotate(+15deg)" }}
                        />
                      </Stack>
                    </Card>
                    <Typography variant="h6" align="center">
                      Code #{code} has been scanned. You've added{" "}
                      {scanData.points} points to your team!
                    </Typography>
                  </>
                ) : (
                  // Unsuccessful Code Scan
                  <>
                    <Card
                      sx={{
                        color: "error.contrastText",
                        backgroundColor: "error.main",
                      }}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <UWIcon icon_name="BINOCULARS" font_size="50px" />
                        <Typography variant="h3" align="center">
                          Uh oh!
                        </Typography>
                        <UWIcon icon_name="BINOCULARS" font_size="50px" />
                      </Stack>
                    </Card>
                    <Typography variant="h6" align="center">
                      Could not submit code #{code}!<br />
                    </Typography>
                    <Typography variant="h6" align="center">
                      <div
                        style={{
                          fontSize: "1rem",
                          fontStyle: "italic",
                          textDecoration: "underline",
                        }}
                      >
                        Error Message:
                      </div>
                      <div>{scanData.msg}</div>
                    </Typography>
                    <Typography
                      variant="b1"
                      align="center"
                      style={{ fontStyle: "italic" }}
                    >
                      Hint: Check the Toolbox for where to find more QrCodes!
                    </Typography>
                  </>
                )}
                <Button
                  variant="contained"
                  color="engPrimary"
                  endIcon={<SendIcon />}
                  onClick={() => (window.location.href = "/teams")}
                >
                  <Stack>
                    <Typography variant="button" align="center">
                      Check your Team's points
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="buttonSecondary"
                        align="center"
                        className={userData.colour_code}
                        sx={{
                          padding: "0.25rem",
                          borderRadius: "0.5rem",
                        }}
                      >
                        {userData.colour} {userData.team_name}
                      </Typography>
                    </Box>
                  </Stack>
                </Button>
              </Stack>
            </Card>
          </Box>
        </Box>
      </div>
    );
};

export default QrSubmitPage;
