import {
  Box,
  Stack,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const StudentGroups = () => {
  const engsoc_roles = [
    {
      role: "Prez & VP",
      blurb:
        "Elected for each society, these Executives serve for 16 months contributing a significant amount of time and effort to the success of EngSoc and our Waterloo Engineeering way of life. There are 4 Vice-Presidents (VPs) - Academic, Communications, Finance and Student Life.",
    },
    {
      role: "Commissioner",
      blurb:
        "Appointed at the end of each term for the next on-stream term, they act as liasons between Directors and Executives. They ensure the smooth running of EngSoc by helping out with some of the bigger services and events run each term.",
    },
    {
      role: "Event Directors",
      blurb:
        "Each term, nearly 100 Directors are appointed by EngSoc Executives to help run events for a duration of 4 months. Applications are open regardless of experience or year of study. With so many positions, there is at least one that would be a good fit for you in line with your interests.",
    },
    {
      role: "Class Reps",
      blurb:
        "At the beginning of a term, each engineering class elects 2 people to represent them in EngSoc Council Meetings. They aim to keep you informed of EngSoc's events, services, representation, to help the class be more involved and raise class spirit. They should know what is going on and get you in touch with the right people if you have any questions/concerns.",
    },
  ];

  const affiliate_logos = [
    {
      path: "/images/affiliate-logos/engiqueers.png",
      alt: "Engiqueers",
    },
    {
      path: "/images/affiliate-logos/nsbe.png",
      alt: "NSBE",
    },
    {
      path: "/images/affiliate-logos/wie.png",
      alt: "Women in Engineering",
    },
    {
      path: "/images/affiliate-logos/eng-without-borders.png",
      alt: "Engineers Without Borders",
    },
    {
      path: "/images/sponsors/gold/weef.png",
      alt: "Weef",
    },
    {
      path: "https://uwaterloo.ca/engineering-equity-diversity/sites/default/files/uploads/images/aises-logo_0.png",
      alt: "AISES",
    },
  ];

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Stack
        justifyContent="center"
        alignItems="stretch"
        sx={{ width: "100%" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "2em", backgroundColor: "engSecondary.light" }}
        >
          <Stack justifyContent="center" aligntItems="center">
            <Typography variant="h3" align="center">
              Get Involved!
            </Typography>
            <Divider orientation="horizontal" />
            <Typography variant="h5" align="center">
              There are a bunch of different ways to get involved with our
              campus communities. Here's a brief on the different things that
              are waiting to be experienced by you!
            </Typography>
          </Stack>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "2em", backgroundColor: "#FFFFFF" }}
        >
          <Stack justifyContent="center" aligntItems="center" spacing={2}>
            <Box display="flex" justifyContent="center" alingContent="center">
              <img
                src="/images/SDC.png"
                alt="Design Teams in Action"
                style={{
                  objectFit: "contain",
                  width: "100%",
                  maxWidth: "min(100%, 500px)",
                }}
              />
            </Box>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Typography variant="h3" align="center">
                Sedra Design Center (SDC) Student Design Teams
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Typography
                variant="b1"
                align="center"
                sx={{ maxWidth: "min(100%, 1000px)" }}
              >
                The SDC is a state-of-the art 20,000 square foot facility home
                to over 20 of the Faculty of Engineering student competition
                teams. Equipped with spaces that facilitate sanding, painting,
                engine testing, electronics assembly, etc. it is the perfect
                place to use and learn most skills you need. In order to join,
                just email them or walk into the team bay/meeting. The time
                commitment also depends on you!
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Typography
                variant="h5"
                align="center"
                sx={{ maxWidth: "min(100%, 800px)" }}
              >
                See you at the Student Team Showcase on Thursday, September 5th
                2024 from 3-5pm.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Button
                variant="contained"
                color="engPrimary"
                onClick={() =>
                  window.open(
                    "https://uwaterloo.ca/sedra-student-design-centre/directory-teams",
                    "_blank"
                  )
                }
                sx={{
                  fontSize: "1.25em",
                  padding: "0.5em",
                  maxWidth: "min(100%, 600px)",
                  minWidth: "300px",
                }}
              >
                Go to SDC Design Team Directory
              </Button>
            </Box>
          </Stack>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "2em", backgroundColor: "engSecondary.light" }}
        >
          <Stack justifyContent="center" aligntItems="center" spacing={2}>
            <Box display="flex" justifyContent="center" alingContent="center">
              <img
                src="/images/resource-logos/engsoc.png"
                alt="EngSoc Logo"
                style={{ maxWidth: "150px", objectFit: "contain" }}
              />
            </Box>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Typography variant="h3" align="center">
                Waterloo Engineering Society (EngSoc)
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Typography
                variant="b1"
                align="center"
                style={{ maxWidth: "min(100%, 1000px)" }}
              >
                EngSoc is the largest and most active student society on campus
                and the largest engineering society of its kind in Canada. All
                undergraduate engineering students, incoming or returning, are
                automatically registered members. It operates on the three
                pillars of Representation, Services and Events.
                <br />
                EngSoc has a variety of social events every term that cater to a
                multitude of unique interests, such as the Semi Formal, Puppies
                in POETS, Genius Bowl, First Year Mentoring and many more.
                Events are thoroughly advertised on EngSoc/s social media pages,
                mailing list, poster boards, and events calendar.
                <br />
                You can find more information on the EngSoc LinkTree here!
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Button
                variant="contained"
                color="engPrimary"
                onClick={() =>
                  window.open("https://linktr.ee/uwengsoc", "_blank")
                }
                sx={{
                  fontSize: "1.25em",
                  padding: "0.5em",
                  maxWidth: "min(100%, 600px)",
                  minWidth: "300px",
                }}
              >
                Go to EngSoc LinkTree
              </Button>
            </Box>
            <Box display="flex" justifyContent="center">
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="flex-start"
                sx={{ maxWidth: "100%" }}
              >
                {engsoc_roles.map((item, index) => (
                  <Grid item xs={12} sm={6} md={3}>
                    <Card>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ padding: "2em", backgroundColor: "#FFFFFF" }}
                      >
                        <Stack justifyContent="center" aligntItems="center">
                          <Typography variant="h5" align="center">
                            {item.role}
                          </Typography>
                          <Divider orientation="horizontal" />
                          <Typography variant="b1" align="center">
                            {item.blurb}
                          </Typography>
                        </Stack>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "2em", backgroundColor: "#FFFFFF" }}
        >
          <Stack justifyContent="center" aligntItems="center" spacing={2}>
            <Typography variant="h3" align="center">
              EngSoc Affiliate Groups
            </Typography>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Typography
                variant="b1"
                align="center"
                style={{ maxWidth: "min(100%, 800px)" }}
              >
                There are many ways to supplement your academics while
                representing your engineering spirit. Writing for the
                Engineering Newspaper, performing in "With Respect to Time"
                (Eng's jazz band), getting involved with Engiqueers to help
                organize events, there are many many ways to get involved with
                EngSoc's many affiliate groups! Affiliates are included in the
                Engineering Society governing documents and include many groups,
                some of which are shown below.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Button
                variant="contained"
                color="engPrimary"
                onClick={() =>
                  window.open(
                    "https://www.engsoc.uwaterloo.ca/about-us/affiliates/",
                    "_blank"
                  )
                }
                sx={{
                  fontSize: "1.25em",
                  padding: "0.5em",
                  maxWidth: "min(100%, 600px)",
                  minWidth: "300px",
                }}
              >
                Go to the EngSoc Affiliates Page
              </Button>
            </Box>
            <Box display="flex" justifyContent="center">
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
                sx={{ padding: "1em", width: "100%" }}
              >
                {affiliate_logos.map((logo, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alingContent="center"
                    >
                      <img
                        src={logo.path}
                        alt={logo.alt}
                        style={{ maxWidth: "100%", objectFit: "contain" }}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "2em", backgroundColor: "engSecondary.light" }}
        >
          <Stack
            justifyContent="center"
            aligntItems="center"
            spacing={2}
            sx={{ paddingBottom: "2em" }}
          >
            <Box display="flex" justifyContent="center" alingContent="center">
              <img
                src="/images/resource-logos/wusa.png"
                alt="WUSA Logo"
                style={{ maxWidth: "min(100%, 400px)", objectFit: "contain" }}
              />
            </Box>
            <Typography variant="h3" align="center">
              WUSA Clubs
            </Typography>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Typography
                variant="b1"
                align="center"
                style={{ maxWidth: "min(100%, 700px)" }}
              >
                Waterloo Undergraduate Student Association (WUSA) supports clubs
                dedicated to every interest and activity under the sun, ranging
                from hobbies, studies, charities, social movements, religions
                and more! You can check out their webpage for more information
                and to find a club that you would like to join.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alingContent="center">
              <Button
                variant="contained"
                color="engPrimary"
                onClick={() => window.open("https://wusa.ca/clubs/", "_blank")}
                sx={{
                  fontSize: "1.25em",
                  padding: "0.5em",
                  maxWidth: "min(100%, 600px)",
                  minWidth: "300px",
                }}
              >
                Go to WUSA Clubs Page
              </Button>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default StudentGroups;
