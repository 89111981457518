import React, { useState, useEffect, useRef } from "react";

const useFitText = ({ maxSize, minSize }) => {
  const MIN_FONT_SIZE = minSize || 14;
  const MAX_FONT_SIZE = maxSize || 36;
  const RESOLUTION = 1;

  // Primary reference to the item that is effected by the font resizing
  const itemRef = useRef(null);
  // OPTIONAL reference to the item above should fit into. If not specidied, best
  // effort is made to use the items parent
  const containerRef = useRef(null);
  // Flag to indicate if the font size has been fully computed
  const [isComputed, setIsComputed] = useState(false);

  const [state, setState] = useState({
    fontSize: MAX_FONT_SIZE,
    fontSizePrev: MIN_FONT_SIZE,
    fontSizeMax: MAX_FONT_SIZE,
    fontSizeMin: MIN_FONT_SIZE,
  });
  const { fontSize, fontSizeMax, fontSizeMin, fontSizePrev } = state;

  // Enable forcing the component to recalculate the font size
  const forceRecalc = () => {
    setState({
      fontSize: MAX_FONT_SIZE,
      fontSizePrev: MIN_FONT_SIZE,
      fontSizeMax: MAX_FONT_SIZE,
      fontSizeMin: MIN_FONT_SIZE,
    });
    setIsComputed(false);
  };

  useEffect(() => {
    const isDone = Math.abs(fontSize - fontSizePrev) <= RESOLUTION;
    const isOverflow =
      !!itemRef.current &&
      (itemRef.current.scrollHeight >
        (!!containerRef.current
          ? containerRef.current.offsetHeight
          : itemRef.current.offsetHeight) ||
        itemRef.current.scrollWidth >
          (!!containerRef.current
            ? containerRef.current.offsetWidth
            : itemRef.current.offsetWidth));
    const isAsc = fontSize > fontSizePrev;

    // return if the font size has been adjusted "enough" (change within RESOLUTION)
    // reduce font size by one increment if it's overflowing
    if (isDone) {
      if (isOverflow) {
        const fontSizeNew =
          fontSizePrev < fontSize
            ? fontSizePrev
            : fontSize - (fontSizePrev - fontSize);
        setState({
          fontSize: fontSizeNew,
          fontSizeMax,
          fontSizeMin,
          fontSizePrev,
        });
      }
      setIsComputed(true);
      return;
    }

    // binary search to adjust font size
    let delta;
    let newMax = fontSizeMax;
    let newMin = fontSizeMin;
    if (isOverflow) {
      delta = isAsc ? fontSizePrev - fontSize : fontSizeMin - fontSize;
      newMax = Math.min(fontSizeMax, fontSize);
    } else {
      delta = isAsc ? fontSizeMax - fontSize : fontSizePrev - fontSize;
      newMin = Math.max(fontSizeMin, fontSize);
    }
    setState({
      fontSize: fontSize + delta / 2,
      fontSizeMax: newMax,
      fontSizeMin: newMin,
      fontSizePrev: fontSize,
    });
  }, [fontSize, fontSizeMax, fontSizeMin, fontSizePrev, itemRef]);

  return {
    fontSize: `${fontSize}pt`,
    itemRef: itemRef,
    containerRef: containerRef,
    forceRecalc: forceRecalc,
    isComputed: isComputed,
  };
};

export default useFitText;
