import PlumbingIcon from "@mui/icons-material/Plumbing";

import "./theToolText.css";

export default function TheTool() {
  return (
    <a
      href="https://www.engsoc.uwaterloo.ca/about-us/traditions/"
      style={{ overflow: "hidden" }}
    >
      <span className="the-tool-wrench-container">
        <PlumbingIcon fontSize="inherit" className="the-tool-wrench" />
      </span>
      <span className="the-tool-text">The Tool</span>
    </a>
  );
}
