import React from "react";
import { useCookies } from "react-cookie";
import { Typography, Box, Stack } from "@mui/material";

const About = () => {
  const userCookieName = process.env.REACT_APP_USER_COOKIE_NAME;
  const [cookies] = useCookies([userCookieName]);
  const userData = cookies[userCookieName];

  return (
    <Box display="flex" justifyContent="center">
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ maxWidth: "1000px", padding: "1em" }}
      >
        <Typography variant="h2" align="center" color="engPrimary">
          Waterloo Engineering Orientation
        </Typography>
        <Typography variant="b1" align="justify" color="black">
          <p>
            It is a vibrant and exhilarating week-long orientation program
            organized by the Waterloo Engineering community. It serves as a warm
            welcome to over 2000 students, helping them integrate into the
            dynamic and close-knit Waterloo Engineering community. Orientation
            Week is a cherished tradition at Waterloo Engineering, encompassing
            a series of engaging and exciting events designed to introduce
            students to the community, its traditions, and the spirited
            atmosphere that defines Waterloo Engineering.
          </p>

          <p>
            With its rich history and strong student involvement, Orientation
            Week holds a special place in the hearts of thousands of students
            each year. Many students eagerly participate, creating a memorable
            and meaningful experience for them and their new peers. Orientation
            Week fosters a sense of camaraderie, teamwork, and inclusivity, and
            sets the foundation for lasting friendships and connections
            throughout students' engineering journey at Waterloo.
          </p>

          <p>
            During Orientation Week, incoming students have the opportunity to
            engage in a wide range of activities, including team-building
            exercises, campus tours, workshops, and social events. These
            experiences not only provide practical knowledge and resources but
            also instill a sense of pride and belonging within the Waterloo
            Engineering community.
          </p>

          <p>
            By participating in Orientation Week, students can quickly acclimate
            to their new academic environment, establish a support network of
            peers, and familiarize themselves with the unique traditions and
            values of Waterloo Engineering. It is an exciting and transformative
            experience that sets the stage for a successful and fulfilling
            undergraduate career at Waterloo.
          </p>

          <p>
            Overall, Orientation Week is a highly anticipated and celebrated
            event that symbolizes the welcoming and inclusive spirit of Waterloo
            Engineering. It serves as an integral part of the transition process
            for incoming students, offering a memorable introduction to the
            vibrant community they are about to join.
          </p>
        </Typography>
      </Stack>
    </Box>
  );
};

export default About;
