import React, { useRef, useEffect, useState } from "react";
import { SvgIcon } from "@mui/material";

import * as icons from "./icons";

function UWIcon(props) {
  const { icon_name, font_size, ...other_props } = props;
  const iconComponent = icons[icon_name];

  var size;
  switch (font_size) {
    case undefined:
      size = "24px";
      break;
    case "small":
      size = "16px";
      break;
    case "medium":
      size = "24px";
      break;
    case "large":
      size = "32px";
      break;
    case "inherit":
      size = "100%";
      break;
    default:
      size = font_size;
      break;
  }

  return (
    <SvgIcon
      component={iconComponent}
      inheritViewBox
      sx={{
        width: size,
        height: size,
      }}
      {...other_props}
    />
  );
}

export default UWIcon;
