import React from "react";
import { Typography, Stack, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingContent = () => {
  return (
    <Box display="flex" justifyContent="center">
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ padding: "30px" }}
      >
        <Typography variant="h2" align="center" sx={{ fontSize: "14pt" }}>
          Collecting From the Database
        </Typography>
        <CircularProgress />
        <Typography variant="h2" align="center" sx={{ fontSize: "14pt" }}>
          If this takes too long, try refreshing the page.
        </Typography>
      </Stack>
    </Box>
  );
};

export default LoadingContent;
