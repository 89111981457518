import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  Grid,
  Stack,
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PlumbingIcon from "@mui/icons-material/Plumbing";

import OrientationLogo from "../../util/OrientationLogo";
import WaterlooEngLogo from "../../util/WaterlooEngLogo";
import WaterlooLogo from "../../util/WaterlooLogo";

// Update this value once you have updated for the coming year.
// This will be used to determine when to show the "needs update"
// disclaimer.
const updatedFor = 2024;

const HeaderToolbar = () => {
  // Get user cookie and
  const userCookieName = process.env.REACT_APP_USER_COOKIE_NAME;
  const [cookies] = useCookies([userCookieName]);
  const userCookie = cookies[userCookieName];
  const loggedIn = !!userCookie;
  const leaderResources = loggedIn && userCookie?.position != "fy";

  const [anchorElNav, setAnchorElNav] = React.useState(null); // Anchor for header collapsed menu
  const [anchorElSubNav, setAnchorElSubNav] = React.useState({}); // Anchor for header submenu

  const pages = [
    { name: "Home", path: "/", include: true, subpages: null },
    { name: "About", path: "/about", include: true, subpages: null },
    { name: "The Book", path: "/book", include: true, subpages: null },
    { name: "Teams", path: "/teams", include: true, subpages: null },
    {
      name: "Leader Resources",
      path: "/leader-resources",
      include: leaderResources,
      subpages: null,
    },
    {
      name: "Waterloo 101",
      path: null,
      include: true,
      subpages: [
        { name: "Maps", path: "/resources/maps" },
        { name: "Move In", path: "/resources/move-in" },
        { name: "Quick Links", path: "/resources/quick-links" },
        { name: "Student Groups", path: "/resources/student-groups" },
        {
          name: "Inclusivity & Wellness",
          path: "/resources/inclusivity-wellness",
        },
      ],
    },
  ];

  // Set the anchor list the first time the page is rendered
  useEffect(() => {
    var anchorDataSubNav = {};
    pages.forEach((page) => {
      if (page.include && page.subpages) {
        anchorDataSubNav[page.name] = null;
      }
    }, <></>);
    setAnchorElSubNav(anchorDataSubNav);
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Toolbar>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        justifyItems="center"
        alignContent="center"
        paddingLeft="1em"
        paddingRight="1em"
      >
        {/* Orientation and Engineering Logos */}
        <Grid
          item
          xs={true}
          sx={{ height: "48px", box: { p: 0 }, button: { p: 0 } }}
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Button
              size="large"
              onClick={() => (window.location.href = "/")}
              color="engSecondary"
              variant="text-white"
            >
              <OrientationLogo width="auto" height="45px" />
            </Button>
            <Box display={{ xs: "none", sm: "block" }}>
              <Button
                size="large"
                onClick={() =>
                  (window.location.href = "https://uwaterloo.ca/engineering/")
                }
                color="engSecondary"
                variant="text-white"
              >
                <WaterlooEngLogo width="auto" height="32px" />
              </Button>
            </Box>
            <Box display={{ xs: "none", ssm: "block", sm: "none" }}>
              <Button
                size="large"
                onClick={() =>
                  (window.location.href = "https://uwaterloo.ca/engineering/")
                }
                color="engSecondary"
                variant="text-white"
              >
                <WaterlooLogo width="auto" height="32px" />
              </Button>
            </Box>
          </Box>
        </Grid>
        {/* Expanded Page Options */}
        <Grid
          item
          display={{
            xs: "none",
            md: leaderResources ? "none" : "block",
            lg: "block",
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ button: { padding: "0px", marginRight: "1rem" } }}
          >
            {pages
              .filter((page) => page.include)
              .map((page) => {
                if (page.subpages) {
                  return (
                    <>
                      <Button
                        color="engSecondary"
                        variant="text-white"
                        onClick={(event) => {
                          setAnchorElSubNav({
                            ...anchorElSubNav,
                            [page.name]: event.currentTarget,
                          });
                        }}
                      >
                        <Typography
                          key={page.name}
                          variant="h6"
                          fontSize="1rem"
                          noWrap
                        >
                          {page.name}
                        </Typography>
                      </Button>
                      <Menu
                        key={`${page.name}-sub`}
                        keepMounted
                        anchorEl={anchorElSubNav[page.name]}
                        open={Boolean(anchorElSubNav[page.name])}
                        onClose={() => {
                          setAnchorElSubNav({
                            ...anchorElSubNav,
                            [page.name]: null,
                          });
                        }}
                      >
                        {page.subpages.map((subpage) => (
                          <MenuItem
                            key={`${page.name}-sub-${subpage.name}`}
                            onClick={() => {
                              setAnchorElSubNav({
                                ...anchorElSubNav,
                                [page.name]: null,
                              });
                              window.location.href = subpage.path;
                            }}
                          >
                            {subpage.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  );
                } else {
                  return (
                    <Button
                      color="engSecondary"
                      variant="text-white"
                      onClick={(event) => {
                        window.location.href = page.path;
                      }}
                    >
                      <Typography
                        key={page.name}
                        variant="h6"
                        fontSize="1rem"
                        noWrap
                      >
                        {page.name}
                      </Typography>
                    </Button>
                  );
                }
              })}
          </Box>
        </Grid>
        {/* Collapsed Page Menu */}
        <Grid
          item
          display={{
            xs: "block",
            md: leaderResources ? "block" : "none",
            lg: "none",
          }}
          sx={{ height: "48px" }}
        >
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="engSecondary"
              variant="text-white"
              onClick={handleOpenNavMenu}
              sx={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <Stack
                direction="column"
                spacing={0}
                justifyContent="center"
                alignItems="center"
                alignContent="center"
              >
                <MenuIcon />
                <Typography
                  variant="h6"
                  align="center"
                  noWrap
                  sx={{ fontSize: "0.6rem" }}
                >
                  Pages
                </Typography>
              </Stack>
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages
                .filter((page) => page.include)
                .map((page) => {
                  if (page.subpages) {
                    return (
                      <>
                        <MenuItem
                          key={`${page.name}-main`}
                          onClick={(event) => {
                            setAnchorElSubNav({
                              ...anchorElSubNav,
                              [page.name]: event.currentTarget,
                            });
                          }}
                        >
                          <Typography textAlign="center" href={page.path}>
                            {page.name}
                          </Typography>
                        </MenuItem>
                        <Menu
                          key={`${page.name}-sub`}
                          id="simple-menu"
                          anchorEl={anchorElSubNav[page.name]}
                          keepMounted
                          open={Boolean(anchorElSubNav[page.name])}
                          onClose={() => {
                            handleCloseNavMenu();
                            setAnchorElSubNav({
                              ...anchorElSubNav,
                              [page.name]: null,
                            });
                          }}
                        >
                          {page.subpages.map((subpage) => (
                            <MenuItem
                              key={`${page.name}-sub-${subpage.name}}`}
                              onClick={() => {
                                handleCloseNavMenu();
                                setAnchorElSubNav({
                                  ...anchorElSubNav,
                                  [page.name]: null,
                                });
                                window.location.href = subpage.path;
                              }}
                            >
                              {subpage.name}
                            </MenuItem>
                          ))}
                        </Menu>
                      </>
                    );
                  } else {
                    return (
                      <MenuItem
                        key={page.name}
                        onClick={() => {
                          handleCloseNavMenu();
                          window.location.href = page.path;
                        }}
                      >
                        <Typography textAlign="center" href={page.path}>
                          {page.name}
                        </Typography>
                      </MenuItem>
                    );
                  }
                })}
            </Menu>
          </Box>
        </Grid>
        {/* Profile / Login Button */}
        <Grid item sx={{ height: "48px" }}>
          <Box display="flex" justifyContent="flex-end">
            <Tooltip title="View My Info and Colour Team">
              <Button
                color="engSecondary"
                variant="text-white"
                onClick={() => {
                  window.location.href = "/profile";
                }}
                sx={{
                  padding: "0px",
                  margin: "0px",
                }}
              >
                <Stack
                  direction="column"
                  spacing={0}
                  justifyContent="center"
                  alignItems="center"
                  alignContent="center"
                >
                  {loggedIn ? <AccountCircleIcon /> : <LoginIcon />}
                  <Typography
                    variant="h6"
                    align="center"
                    noWrap
                    sx={{ fontSize: "0.6rem" }}
                  >
                    {loggedIn ? "Profile" : "Login"}
                  </Typography>
                </Stack>
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

// Engineering Purple Colour Bar
const PurpleBar = () => {
  return (
    <Box width="100%">
      <Grid container>
        {["#C2A8F0", "#A05DCB", "#865DA4", "#5D0096"].map((color, index) => (
          <Grid item xs="3" alignItems="stretch" key={index}>
            <Box
              display="flex"
              sx={{
                background: color,
                width: "100%",
                height: "10px",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

// Needs Update Disclaimer
const UpdateDisclaimer = () => {
  // This function will check if the current year is greater than the
  // year that the site was last updated for. If it is, it will return
  // true, otherwise it will return false.
  function checkNeedsUpdate() {
    const CurrentDate = new Date();
    const CurrentYear = CurrentDate.getFullYear();
    if (CurrentYear > updatedFor) {
      return true;
    } else {
      return false;
    }
  }

  return checkNeedsUpdate() ? (
    <Box
      width="100%"
      padding="0.5em"
      onClick={() => {
        if (window.location.pathname != "/") window.location.href = "/";
        else return;
      }}
      sx={{
        cursor: "pointer",
        backgroundColor: "engSecondary.light",
        color: "engSecondary.contrastText",
        transition: "background-color 0.5s ease",
        "&:hover": {
          backgroundColor: "engSecondary.main",
        },
      }}
    >
      {window.location.pathname == "/" ? (
        <Stack spacing={2} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <PlumbingIcon
              fontSize="large"
              style={{ transform: "scale(-1,1)" }}
            />
            <Typography variant="h5" align="center">
              We are working on updating this site for the{" "}
              {new Date().getFullYear()} school year!
            </Typography>
            <PlumbingIcon fontSize="large" />
          </Stack>
          <Stack item style={{ maxWidth: "1000px" }}>
            <Typography variant="body1" align="center">
              The orientation team is working hard to update this site for the{" "}
              {new Date().getFullYear()} school year. Please check back later
              for more information! If you have any questions, please contact{" "}
              <a
                href="mailto:engorientation@uwaterloo.ca"
                style={{ color: "#0B2789" }}
              >
                engorientation@uwaterloo.ca
              </a>
              .
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Box display="flex" justifyContent="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <PlumbingIcon
              fontSize="medium"
              style={{ transform: "scale(-1,1)" }}
            />
            <Typography variant="h6" align="center">
              We are working on updating this site for the{" "}
              {new Date().getFullYear()} school year!
            </Typography>
            <PlumbingIcon fontSize="medium" />
          </Stack>
        </Box>
      )}
    </Box>
  ) : (
    <></>
  );
};

function Navbar() {
  return (
    <div>
      <AppBar>
        <HeaderToolbar />
        <PurpleBar />
        <UpdateDisclaimer />
      </AppBar>
    </div>
  );
}

export default Navbar;
