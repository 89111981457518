import React, { Component } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import DiscordIcon from "@mui/icons-material/Message";
import LinkIcon from "@mui/icons-material/Link";
import FlickrIcon from "@mui/icons-material/Camera";
import { Button } from "@mui/material";

function SocialLink({ url }) {
  if (!url) return <></>;
  const domainRegex =
    /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+\.)([^:\/\n\.]+\.[^:\/\n\.]+)/;
  const match = url.match(domainRegex);
  const domain = match ? match[2] : null;

  switch (domain) {
    case "instagram.com":
      return (
        <Button
          variant="contained"
          color="engPrimary"
          startIcon={<InstagramIcon />}
          onClick={() => (window.location.href = url)}
        >
          Instagram
        </Button>
      );
    case "facebook.com":
      return (
        <Button
          variant="contained"
          color="engPrimary"
          startIcon={<FacebookIcon />}
          onClick={() => (window.location.href = url)}
        >
          Facebook
        </Button>
      );
    case "twitter.com":
      return (
        <Button
          variant="contained"
          color="engPrimary"
          startIcon={<TwitterIcon />}
          onClick={() => (window.location.href = url)}
        >
          Twitter
        </Button>
      );
    case "discord.gg":
      return (
        <Button
          variant="contained"
          color="engPrimary"
          startIcon={<DiscordIcon />}
          onClick={() => (window.location.href = url)}
        >
          Discord
        </Button>
      );
    case "flickr.com":
      return (
        <Button
          variant="contained"
          color="engPrimary"
          startIcon={<FlickrIcon />}
          onClick={() => (window.location.href = url)}
        >
          Flickr
        </Button>
      );
    default:
      if (domain === null) {
        return (
          <Button
            variant="contained"
            color="engPrimary"
            startIcon={<LinkIcon />}
            onClick={() => (window.location.href = url)}
          >
            Link
          </Button>
        );
      } else {
        return (
          <Button
            variant="contained"
            color="engPrimary"
            startIcon={<LinkIcon />}
            onClick={() => (window.location.href = url)}
          >
            {domain}
          </Button>
        );
      }
  }
}

export default SocialLink;
